<!-- 首页弹窗 -->
<template>
  <div class="Map">
    <baidu-map
      class="map"
      :center="center"
      :zoom="zoom"
      @ready="handler"
      :min-zoom="zoomMin"
      @click="getClickInfo"
      :scroll-wheel-zoom="true"
    >
      <!--      BMAP_ANCHOR_TOP_LEFT-->
      <!--      BMAP_ANCHOR_BOTTOM_RIGHT-->
      <!--      :offset="{ width: 500, height: 200 }"-->
      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                   :offset="offsetBmap"></bm-map-type>

      <bm-polyline :path="Huangcaihe" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>

      <bm-label content="黄蔡河" :position="{lng: 115.07191982186525, lat: 34.90711948010067}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none',paddingRight:'20px'}"/>

      <!-- 北沙河 -->
      <bm-polyline :path="BeishaRiver" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>
      <bm-label content="北沙河" :position="{lng: 115.15078655411376,lat:34.884303951350745}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none',paddingLeft:'10px'}"/>

      <bm-label content="北沙河" :position="{lng: 115.03230904040278,lat:34.87873325270226}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none',paddingLeft:'10px'}"/>

      <!--  东方红干渠 -->
      <bm-polyline :path="Dongfanghongmaincanal" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>

      <bm-label content="东方红干渠" :position="{lng: 114.8194735159237,lat:34.934767100523665}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none',paddingLeft:'10px'}"/>

      <!-- 兰东干渠 -->
      <bm-polyline :path="Landongmaincanal" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>
      <bm-label content="兰东干渠" :position="{lng: 114.91305449242566,lat:34.94381444703951}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <!-- 兰东干渠2 -->
      <bm-polyline :path="Landongmaincanal2" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>
      <bm-label content="兰东干渠" :position="{lng: 114.978581387851,lat:34.98237457977539}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <!--  兰开干渠 -->
      <bm-polyline :path="Lancaitrunkcanal" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>

      <!-- 兰杞干渠 -->
      <bm-polyline :path="Lanqimaincanal" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>
      <bm-label content="兰杞干渠" :position="{lng: 114.74992221181365,lat:34.843045837574095}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>
      <bm-label content="兰杞干渠" :position="{lng: 114.76420077789476,lat:34.77710580155204}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <!-- 四明河 -->
      <bm-polyline :path="SimingRiver" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>
      <bm-label content="四明河" :position="{lng: 114.90774550686169,lat:34.959857509068904}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <bm-label content="四明河" :position="{lng: 115.13662476774897,lat:34.96599948392229}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <!-- 贺李河 -->
      <bm-polyline :path="Helihe" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>
      <bm-label content="贺李河" :position="{lng: 115.11116229806865,lat:34.835668712523365}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>


      <bm-label content="贺李河" :position="{lng: 115.24094947815026,lat:34.81009173710209}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <!-- 张新河 -->
      <bm-polyline :path="Zhangxinhe" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>
      <bm-label content="张新河" :position="{lng: 115.10933424635584,lat:34.83072803591696}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <!-- 魏东干渠 -->
      <bm-polyline :path="Weidongmaincanal" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>
      <bm-label content="魏东干渠" :position="{lng: 115.11135992528084,lat:34.802126234587874}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <!-- 商丘干渠 -->
      <bm-polyline :path="Shangqiumaincanal" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>
      <bm-label content="商丘干渠" :position="{lng: 115.01621589719153,lat:34.81045850199603}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <bm-label content="商丘干渠" :position="{lng: 114.83336581063551,lat:34.84675266510707}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <!-- 金狮沟 -->
      <bm-polyline :path="WestBranchofduzhuangRiver" stroke-color="#419DEC" :stroke-opacity="0"
                   :stroke-weight="4"></bm-polyline>

      <bm-label content="金狮沟" :position="{lng: 114.80208681277807,lat:34.816993309380656}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <bm-label content="金狮沟" :position="{lng: 114.81034673194233,lat:34.78007811999621}"
                :labelStyle="{color: '#999999', fontSize : '12px',background:'none',border:'none'}"/>

      <div v-for="(item, i) in siteVos" :key="i">
        <!-- 在线 -->
        <template v-if="showSiteVos">
          <bm-marker
            v-if="
              item.deviceStatus == '0' &&
              item.waterLevelAlarmStatus == '0'&&item.accessControlStatus=='0'&&item.perimeterStatus=='0'
            "
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/zaixian.png'),
              size: { width: 35, height: 41 },
            }"
            :zIndex="9"
            @click="openInfoBox(item.lng, item.lat, item.id,item.siteName,item)"

          >
            <bm-label :content="item.siteName"
                      :zIndex="9"
                      :labelStyle="labelStyle1"
                      :offset="{ width: 35, height: 6 }"/>
          </bm-marker>

          <!-- 报警 -->
          <bm-marker
            v-if="
              item.deviceStatus == '0' &&
              (item.waterLevelAlarmStatus == '1'||item.accessControlStatus=='1'||item.perimeterStatus=='1')
            "
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/baojing.png'),
              size: { width: 35, height: 41 },
            }"
            :zIndex="9"
            @click="openInfoBox(item.lng, item.lat, item.id,item.siteName,item)"
          >
            <bm-label :content="item.siteName"
                      :zIndex="9"
                      :labelStyle="labelStyle1"
                      :offset="{ width: 35, height: 6 }"/>
          </bm-marker>
          <!-- 离线 -->
          <bm-marker
            v-if="item.deviceStatus == '1'"
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/lixian.png'),
              size: { width: 35, height: 41 },
            }"
            :zIndex="9"
            @click="openInfoBox(item.lng, item.lat, item.id,item.siteName,item)"
          >
            <bm-label :content="item.siteName"
                      :zIndex="9"
                      :labelStyle="labelStyle1"
                      :offset="{ width: 35, height: 6 }"/>
          </bm-marker>
        </template>
      </div>

      <!-- 渠道流量计  -->
      <div v-for="(item, index) in ditchFlowMeters" :key="'ditchFlowMeters' + index">
        <template v-if="showFlowMeters">
          <bm-marker
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/llj.png'),
              size: { width: 32, height: 37 },
            }"
            :zIndex="9"

          >
            <!--            @mouseover="openDitchFlowMeterBox(item.lng, item.lat, item.meterId)"-->
            <!--            @mouseout="closeDitchFlowMeterBox"-->
          </bm-marker>
        </template>
      </div>

      <!-- 流量计  -->
      <div v-for="(item, index) in flowMeters" :key="'flowMeters' + index">
        <template v-if="showFlowMeters">
          <bm-marker
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/llj.png'),
              size: { width: 32, height: 37 },
            }"
            :zIndex="9"

          >
            <!--            @mouseover="openFlowMeterBox(item.lng, item.lat, item.devSn)"-->
            <!--            @mouseout="closeFlowMeterBox"-->
          </bm-marker>
        </template>
      </div>

      <!-- 泵站  -->
      <div v-for="(item, index) in pumpStations" :key="'pumpStations' + index">
        <template v-if="showPumpStations">
          <bm-marker
            :position="{ lng: item.lng, lat: item.lat }"
            :icon="{
              url: require('@/assets/images/bz/bz.png'),
              size: { width: 31, height: 35 },
            }"
            :zIndex="9"
            @mouseover="openPumpMeterBox(item.lng, item.lat, item.id)"
            @mouseout="closePumpBox"
            @click="toPumpStationPush(item.id, item.staId)"
          >
          </bm-marker>
        </template>
      </div>
    </baidu-map>
    <div @click="shouqi(falsg)" :class="falsg == true ? 'putaway' : 'putawayFalse'">
      <img v-show="falsg==true" src="@/assets/indexHomeImg/left_back.png" alt="">
      <img v-show="falsg==false" src="@/assets/indexHomeImg/left_open.png" alt="">
    </div>


    <!-- 左边 -->
    <div class="mapHomeLeft" v-if="mapHomeLeftFlag">
      <!-- 管辖区域、河道数量、节制闸数量、测流点数量 -->
      <Cardbox></Cardbox>
      <!-- 闸门状态 -->
      <Gatestatus ref="Gatestatus"></Gatestatus>
      <!-- 站点实时信息 -->
      <Police ref="Police"></Police>
    </div>


    <div @click="rightshouqi(falsg2)" :class="falsg2 == true ? 'putawayright' : 'putawayrightFalse'">
      <img v-show="falsg2 == true" src="@/assets/indexHomeImg/right_back.png" alt="">
      <img v-show="falsg2 == false" src="@/assets/indexHomeImg/right_open.png" alt="">
    </div>
    <!-- 右边 -->
    <div class="mapHomeRight" v-if="mapHomeRightFlag">
      <!-- 警报状态 -->
      <Alarmstatus ref="Alarmstatus"></Alarmstatus>
      <!-- 监控状态 无法从首页跳转是以为监控还在获取声音设备需要将声音设备关闭掉-->
      <Sitemonitoring ref="SiteVideo"></Sitemonitoring>
      <!-- 报警信息 -->
      <Warningmessage></Warningmessage>
    </div>
    <!-- 状态  class="map-data-tip" -->
    <div :class="falsg2 == true ?  'map-data-tip':'map-data-tipFalse'" ref="mapData">
      <div class="typeOne">
        <span>图例</span>
      </div>
      <div class="typeTwo">
        <div class="tip-type">
          <div style="display: flex;justify-content: center;align-items: center">
            <img style="width: 20px;height: 20px" src="@/assets/png/zhengchang.png" alt="">
          </div>
          <span style="padding-left: 5px">正常</span>
        </div>
        <div class="tip-type">
          <div style="display: flex;justify-content: center;align-items: center">
            <img style="width: 20px;height: 20px" src="@/assets/png/lixian.png" alt="">
          </div>
          <span style="padding-left: 5px">离线</span>
        </div>
        <div class="tip-type">
          <div style="display: flex;justify-content: center;align-items: center">
            <img style="width: 20px;height: 20px" src="@/assets/png/bjPli.png" alt="">
          </div>
          <span style="padding-left: 5px">报警</span>
        </div>
      </div>
    </div>
    <!--    class="map-checkbox"-->
    <div v-show="checkboxMap" :class="falsg== true ? 'map-checkbox':'map-checkboxFalse'">
      <el-radio-group
        v-model="checkboxGroup2"
        @change="handleCheckedCitiesChange">
        <el-radio-button v-for="city in cities" :label="city" :key="city"
        >{{ city }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div v-show="icoQuanMap" :class="falsg== true ? 'icoQuan':'icoQuanFalse'">
      <!--      <div><img src="@/assets/images/ico/icolj.png" alt=""></div>-->
      <div><img src="@/assets/images/ico/icozai.png" alt=""/></div>
      <div>
        <img
          style="position: relative; left: -28px"
          src="@/assets/images/ico/icollj.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BeishaRiver,
  Dongfanghongmaincanal,
  Helihe,
  Huangcaihe,
  Lancaitrunkcanal,
  Landongmaincanal,
  Landongmaincanal2,
  Lanqimaincanal,
  Shangqiumaincanal,
  SimingRiver,
  Weidongmaincanal,
  WestBranchofduzhuangRiver,
  Zhangxinhe
} from "../../../../assets/js/database";

const cityOptions = ["全选", "节制闸", "测流点"];
import {
  getAllSiteList,
  getSiteByIdList,
  getRainById,
  getDitchFlowMeter,
  getFlowMeter,
  getPumpStation,
} from "../../../../api/index";
import {llj, qdllj, bz} from "@/assets/js/html";
import {login} from "../../../../api/login";
import Echarts from "../Echarts/index";
import Cardbox from "../Table/components/Cardbox";
import Police from "../Table/components/Police"
import Warningmessage from "../Table/components/Warningmessage"
import Gatestatus from "../Table/components/Gatestatus"
import Alarmstatus from "../Echarts/components/Alarmstatus";
import Sitemonitoring from "../Echarts/components/Sitemonitoring";


export default {
  props: {
    siteId: {
      type: String,
      required: true,
    },
  },
  components: {
    Echarts,
    Cardbox,
    Police,
    Warningmessage,
    Gatestatus,
    Alarmstatus, // 警报状态
    Sitemonitoring, // 站点监控
  },

  data() {
    return {
      checkboxMap: true,
      icoQuanMap: true,
      offsetBmap: {width: 465, height: 280},
      falsg: true,// 0 展示 1 隐藏
      zoomMin: 12,
      falsg2: true,
      mapHomeLeftFlag: true, // 左侧
      mapHomeRightFlag: true, // 右侧
      Huangcaihe: Huangcaihe,
      BeishaRiver: BeishaRiver,
      Dongfanghongmaincanal: Dongfanghongmaincanal,
      Landongmaincanal: Landongmaincanal,
      Lancaitrunkcanal: Lancaitrunkcanal,
      Lanqimaincanal: Lanqimaincanal,
      Landongmaincanal2: Landongmaincanal2,
      SimingRiver: SimingRiver,
      Helihe: Helihe,
      Zhangxinhe: Zhangxinhe,
      Weidongmaincanal: Weidongmaincanal,
      Shangqiumaincanal: Shangqiumaincanal,
      WestBranchofduzhuangRiver: WestBranchofduzhuangRiver,
      infoBox: null,
      lastInfoBox: null,
      checkboxGroup2: "全选",
      checkAll: true,
      isIndeterminate: true,
      labelStyle1: {
        padding: "4px 10px",
        cursor: "pointer",
        zIndex: "20",
        border: "1px solid #7ABAFD",
        borderRadius: "15px",
        "color": "#487CBF",
        background: "#fff",
      },
      cities: cityOptions,
      showPumpStations: true,
      showFlowMeters: true,
      showDitchFlowMeters: true,
      showSiteVos: true,
      isMains: "", // 市电或者太阳能
      siteItemVo: {}, // 获取到的站点信息
      alarmStatus: "", // 获取到的预警状态
      gateVo: {}, // 闸门信息
      siteVos: [], // 站点列表
      rainVos: [], // 雨情列表
      ditchFlowMeters: [], // 渠道流量计
      ditchFlowMeterCount: "",
      flowMeters: [], // 流量计
      flowMeterCount: "",
      pumpStations: [], // 泵站
      pumpStationCount: "",
      center: {
        lng: 114.982763,
        lat: 34.883771,
      },
      zoom: 12,
      // 顺序 雨情 站点 正常 掉线 报警
      normalRainCount: "",
      normalSiteCount: "",
      offlineRainCount: "",
      offlineSiteCount: "",
      warningRainCount: "",
      warningSiteCount: "",
      rainfallTime: "", // 降雨时长
      rainfall: "", // 降雨量
      DongyingData: {},
      mapStyle: {
        styleJson: [{
          "featureType": "boundary",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "districtlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "districtlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "continent",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "country",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "city",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "city",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "district",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "village",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "island",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "airportlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "airportlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "scenicspotslabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "scenicspotslabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "educationlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "educationlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "medicallabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "medicallabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "entertainmentlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "entertainmentlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "estatelabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "estatelabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "businesstowerlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "businesstowerlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "companylabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "companylabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "governmentlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "governmentlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "restaurantlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "restaurantlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "hotellabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "hotellabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "shoppinglabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "shoppinglabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "lifeservicelabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "lifeservicelabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "carservicelabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "carservicelabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "transportationlabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "transportationlabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "financelabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "financelabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "INTERNATIONALIZEICONICON",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "INTERNATIONALIZEICONICON",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "provincialway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "railway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "subway",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "highwaysign",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "highwaysign",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "provincialwaysign",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "provincialwaysign",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "tertiarywaysign",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "tertiarywaysign",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "subwaylabel",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "subwaylabel",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "footbridge",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "crosswalk",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "underpass",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "parkingspace",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "laneline",
          "elementType": "geometry",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "highway",
          "elementType": "geometry.fill",
          "stylers": {
            "color": "#fcfcfcff"
          }
        }, {
          "featureType": "highway",
          "elementType": "geometry.stroke",
          "stylers": {
            "color": "#bdbdbdff"
          }
        }, {
          "featureType": "nationalwaysign",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "nationalwaysign",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "nationalway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "provincialway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "subway",
          "elementType": "labels",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "subway",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }, {
          "featureType": "roadarrow",
          "elementType": "labels.icon",
          "stylers": {
            "visibility": "off"
          }
        }],
      },
    };
  },
  watch: {
    "$store.state.wsRainData"(e) {
      this.normalRainCount = e.normalRainCount;
      this.normalSiteCount = e.normalSiteCount;
      this.offlineRainCount = e.offlineRainCount;
      this.offlineSiteCount = e.offlineSiteCount;
      this.warningRainCount = e.warningRainCount;
      this.warningSiteCount = e.warningSiteCount;
    },
    checkAll(e) {
    },
  },
  mounted() {
    this.show();
  },
  methods: {
    // 左侧收起
    shouqi(val) {
      this.falsg = !this.falsg
      this.mapHomeLeftFlag = !this.mapHomeLeftFlag

    },
    // 右侧收起
    rightshouqi(val) {
      this.falsg2 = !this.falsg2
      this.mapHomeRightFlag = !this.mapHomeRightFlag
      if (this.falsg2 == true) {
        this.offsetBmap.width = 465
      } else {
        this.offsetBmap.width = 65
      }
    },
    // 设备
    show() {
      getAllSiteList().then((res) => {
        // console.log('getAllSiteList', res)
        // this.center = res.data.siteVos[0]
        this.siteVos = res.data.siteVos;
        // console.log('this.siteVos======', this.siteVos);
        this.rainVos = res.data.rainVos;
        this.ditchFlowMeters = res.data.ditchFlowMeters.map((item) => {
          return {...item, lng: item.lon};
        });
        this.ditchFlowMeterCount = res.data.ditchFlowMeterCount;
        this.flowMeters = res.data.flowMeters.map((item) => {
          return {...item, lng: item.lon};
        });
        this.flowMeterCount = res.data.flowMeterCount;
        this.pumpStations = res.data.pumpStations.map((item) => {
          return {...item, lng: item.lon};
        });
        this.pumpStationCount = res.data.pumpStationCount;
        this.normalRainCount = res.data.normalRainCount;
        this.normalSiteCount = res.data.normalSiteCount;
        this.offlineRainCount = res.data.offlineRainCount;
        this.offlineSiteCount = res.data.offlineSiteCount;
        this.warningRainCount = res.data.warningRainCount;
        this.warningSiteCount = res.data.warningSiteCount;
      });
    },
    handleCheckAllChange(val) {
      // this.checkboxGroup2 = val ? cityOptions : [];
      // this.isIndeterminate = false;
      // if(val){
      //     this.showPumpStations=true
      //       this.showFlowMeters=true
      //       this.showSiteVos=true
      // }else{
      //      this.showPumpStations=false
      //       this.showFlowMeters=false
      //       this.showSiteVos=false
      // }
    },
    handleCheckedCitiesChange(value) {
      // console.log(value);
      if (value == "泵站") {
        this.showPumpStations = true;
        this.showSiteVos = false;
        this.showFlowMeters = false;
      }
      if (value == "测流点") {
        this.showFlowMeters = true;
        this.showPumpStations = false;
        this.showSiteVos = false;
      }
      if (value == "节制闸") {
        this.showSiteVos = true;
        this.showPumpStations = false;
        this.showFlowMeters = false;
      }
      if (value == "全选") {
        this.showSiteVos = true;
        this.showPumpStations = true;
        this.showFlowMeters = true;
      }
    },
    goPush(id) {
      this.$router.push({path: `/main/StationManage/${id}`});
    },
    toPumpStationPush(id, staId) {
      this.$router.push({path: `/main/pumpStation/${id}/1-1`});
    },
    // 打开信息窗口
    openInfoBox(lng, lat, id, name, item) {
      this.center.lng = lng
      this.center.lat = lat
      // 点击获取接口数据
      getSiteByIdList({id: id}).then((res) => {
        this.isMains = res.data.isMains;
        this.alarmStatus = res.data.alarmStatus;
        this.siteItemVo = res.data.siteItemVo;
        this.gateVo = res.data.gateVo;

        if (this.falsg == false) {
          this.$refs.Alarmstatus.waterChange(name) // 查询警报状态
          this.$refs.SiteVideo.waterChange(id) // 站点监控
        } else if (this.falsg2 == false) {
          this.$refs.Police.showDownid(id) // 站点实时信息 查询数据
          this.$refs.Police.getCurrentUser(id) // 站点实时信息查询人员信息
          this.$refs.Gatestatus.getInfo(id) // 查询闸门状态
        } else {
          this.$refs.Alarmstatus.waterChange(name) // 查询警报状态
          this.$refs.SiteVideo.waterChange(id) // 站点监控
          this.$refs.Police.showDownid(id) // 站点实时信息 查询数据
          this.$refs.Police.getCurrentUser(id) // 站点实时信息查询人员信息
          this.$refs.Gatestatus.getInfo(id) // 查询闸门状态
        }


        this.checkboxMap = false
        this.icoQuanMap = false


        let html1 = `<div class="rain" style="position: absolute;
    z-index: 9;">
    <div class="rainBox">
      <div style="margin:0 12px">
        <!-- 头部区域 -->
        <div class="rainBox_title flex flex-a-c ">
          <div class="rainBox_title_text  font-w-b" style="color:#2485E7;font-size:18px;">
           ${this.siteItemVo.siteName}
           </div>

          <div class="rainBox_title_num ">
            <span style="font-size:12px">${this.siteItemVo.updateTime}</span>
          </div>


          <div class="closeWindows" id="closeInfoWindows"></div>
        </div>
        <!-- 闸门信息 -->
        <div class="gate">
          <div class="gate_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">闸门信息</p>
          </div>
          <div class="gate_text">
            <ul class="flex ul_one">
              <li>
                <div class=" font-w-b t-x" style="color:#11B36C;font-size:16px;">
                 ${this.gateVo.gateHeight}
                </div>
              </li>
              <li>
                <div class=" font-w-b t-x" style="color:#00a0e9;font-size:16px">
               ${this.gateVo.gateHeightLimit}
                </div>
              </li>
              <li>
                `;
        if (this.siteItemVo.deviceStatus == 0 && this.gateVo.gateHeight > 5) {
          html1 += `<div class=" font-w-b t-x" style="color:#1B9EE8;font-size:16px;">
                 运行中
                </div>`;
        } else if (
          this.siteItemVo.deviceStatus == 0 &&
          this.gateVo.gateHeight <= 5
        ) {
          html1 += `<div class=" font-w-b t-x" style="color:red;font-size:16px;">
                 停止
                </div>`;
        } else if (this.siteItemVo.deviceStatus == 1) {
          html1 += `<div class=" font-w-b t-x" style="color:#999999;font-size:16px;">
                 离线
                </div>`;
        }
        html1 += `</li>
              <li>
                `;
        if (this.siteItemVo.longRange == 0) {
          html1 += `<div class=" font-w-b t-x" style="color:black;font-size:16px">
               本地
                </div>`;
        } else if (this.siteItemVo.longRange == 1) {
          html1 += `<div class=" font-w-b t-x" style="color:#00a0e9;font-size:16px">
               远程
                </div>`;
        }
        html1 += `</li>
            </ul>
            <ul class="flex ul_two">
              <li>
                <div class=" t-x font-s-12" style="color:#333333;font-weight: bold;">
                  闸门高度(cm)
                </div>
              </li>
              <li>
                <div class=" t-x font-s-12" style="color:#333333;font-weight: bold;">
                  闸门限高(cm)
                </div>
              </li>
              <li>
                <div class=" t-x font-s-12" style="color:#333333;font-weight: bold;">
                  闸门状态
                </div>
              </li>
              <li>
                <div class=" t-x font-s-12" style="color:#333333;font-weight: bold;">
                  闸门控制
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="polyin"></div>
        <!-- 水文信息 -->
        <div class="waterMes">
          <div class="waterMes_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">水文信息</p>
          </div>
          <div class="waterMes_text">
            <ul class="flex ul_one" style="flex-wrap: wrap;">
              <li class="flex">
                <div class="font-s-12" style="color:#666;margin:0 0 4px 4px">
                  闸前水位：
                </div>`;
        if (this.siteItemVo.gateLevel >= this.siteItemVo.warningLevel) {
          html1 += `
                <div class="font-w-b font-s-12" style="color:red;">
                ${this.siteItemVo.gateLevel}(cm)
                </div>`;
        } else {
          html1 += `
                <div class="font-w-b font-s-12" style="color:#1B9EE8">
                ${this.siteItemVo.gateLevel}(cm)
                </div>`;
        }
        html1 += `
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin:0 0 4px 4px">
                  闸后水位：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#1B9EE8">
                  ${this.siteItemVo.currentLevel}(cm)
                </div>
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin:0 0 4px 4px">
                  警戒水位：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#1B9EE8">
                  ${this.siteItemVo.warningLevel}(cm)
                </div>
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin:0 0 4px 4px">
                  瞬时流量：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#1B9EE8">
                  ${this.siteItemVo.traffic}(m³/h)
                </div>
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin:0 0 4px 4px">
                  流&emsp;&emsp;速：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#1B9EE8">
                  ${this.siteItemVo.velocity}(m/s)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <!-- 市电信息 -->
        <div class="mete">
          <div class="mete_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">市电信息</p>
          </div>
          <div class="mete_text">
            <ul class="flex ul_two">
              <li class="flex">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                  B相电流：
                </div>
                <div class="font-w-b font-s-12" style="color:#1B9EE8">
                ${this.siteItemVo.bCurrent}(A)
                </div>
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin-left:4px">
                  B相电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#1B9EE8">
                  ${this.siteItemVo.bVoltage}(V)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <div class="solar">
          <div class="solar_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">安防报警</p>
          </div>
          <div class="solar_text">
            <ul class="flex ul_two">
              <li class="flex">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                  门禁预警：
                </div>`;
        if (this.alarmStatus.accessControlStatus == 0) {
          html1 += `<div class="font-w-b font-s-12" style="color:#11B36C">
                正常
                </div>`;
        } else {
          html1 += `<div class="font-w-b font-s-12" style="color:#FA2626" v-else>
                报警
                </div>`;
        }
        html1 += `
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin-left:4px">
                  周界预警：
                </div>`;
        if (this.alarmStatus.perimeterStatus == 0) {
          html1 += `<div class="font-w-b font-s-12" style="color:#11B36C">
                正常
                </div>`;
        } else {
          html1 += `<div class="font-w-b font-s-12" style="color:#FA2626" v-else>
                报警
                </div>`;
        }

        html1 += `
              </li>

            </ul>
          </div>

        </div>
         <div style="padding-top: 5px;text-align: right;cursor: pointer;color:#2485E7;" id="detailClick">
            站点详情 >
         </div>
      </div>
    </div>
  </div>`;

        // <div class="rainBox_title_img" οnclick="closeBtn">
        //             <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg"  alt="" />
        //           </div>
        // 闸门
        let html = `<div class="rain" style="position: absolute;
    z-index: 9;">
    <div class="rainBox">
      <div style="margin:0 12px">
        <!-- 头部区域 -->
        <div class="rainBox_title flex flex-a-c ">
           <div class="rainBox_title_text  font-w-b" style="color:#2485E7;font-size:18px">${this.siteItemVo.siteName}</div>

          <div class="rainBox_title_num ">
            <span style="font-size:12px">${this.siteItemVo.updateTime}</span>
          </div>



        </div>
        <!-- 闸门信息 -->
        <div class="gate">
          <div class="gate_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">闸门信息</p>
          </div>
          <div class="gate_text">
            <ul class="flex ul_one">
              <li>
                <div class=" font-w-b t-x" style="color:#11B36C;font-size:16px;">
                 ${this.gateVo.gateHeight}
                </div>
              </li>
              <li>
                <div class=" font-w-b t-x" style="color:red;font-size:16px">
               ${this.gateVo.gateHeightLimit}
                </div>
              </li>
              <li>
                `;
        if (this.siteItemVo.deviceStatus == 0 && this.gateVo.gateHeight > 5) {
          html += `<div class=" font-w-b t-x" style="color:#1B9EE8;font-size:16px;">
                 运行中
                </div>`;
        } else if (
          this.siteItemVo.deviceStatus == 0 &&
          this.gateVo.gateHeight <= 5
        ) {
          html += `<div class=" font-w-b t-x" style="color:red;font-size:16px;">
                 停止
                </div>`;
        } else if (this.siteItemVo.deviceStatus == 1) {
          html += `<div class=" font-w-b t-x" style="color:#999999;font-size:16px;">
                 离线
                </div>`;
        }
        html += `</li>
              <li>
                `;
        if (this.siteItemVo.longRange == 0) {
          html += `<div class=" font-w-b t-x" style="color:black;font-size:16px">
               本地
                </div>`;
        } else if (this.siteItemVo.longRange == 1) {
          html += `<div class=" font-w-b t-x" style="color:#00a0e9;font-size:16px">
               远程
                </div>`;
        }
        html += `</li>
            </ul>
            <ul class="flex ul_two">
              <li>
                <div class=" t-x font-s-12" style="color:#333333;font-weight: bold;">
                  闸门高度(cm)
                </div>
              </li>
              <li>
                <div class=" t-x font-s-12" style="color:#333333;font-weight: bold;">
                  闸门限高(cm)
                </div>
              </li>
              <li>
                <div class=" t-x font-s-12" style="color:#333333;font-weight: bold;">
                  闸门状态
                </div>
              </li>
              <li>
                <div class=" t-x font-s-12" style="color:#333333;font-weight: bold;">
                  闸门控制
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="polyin"></div>
        <!-- 水文信息 -->
        <div class="waterMes">
          <div class="waterMes_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">水文信息</p>
          </div>
          <div class="waterMes_text">
            <ul class="flex ul_one" style="flex-wrap: wrap;">
              <li class="flex">
                <div class="font-s-12" style="color:#666;margin:0 0 4px 4px">
                  闸前水位：
                </div>
               `;
        if (this.siteItemVo.gateLevel >= this.siteItemVo.warningLevel) {
          html += `
                <div class="font-w-b font-s-12" style="color:red;">
                ${this.siteItemVo.gateLevel}(cm)
                </div>`;
        } else {
          html += `
                <div class="font-w-b font-s-12" style="color:#1B9EE8">
                ${this.siteItemVo.gateLevel}(cm)
                </div>`;
        }
        html += `
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin:0 0 4px 4px">
                  闸后水位：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#1B9EE8">
                  ${this.siteItemVo.currentLevel}(cm)
                </div>
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin:0 0 4px 4px">
                  警戒水位：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#1B9EE8">
                  ${this.siteItemVo.warningLevel}(cm)
                </div>
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin:0 0 4px 4px">
                  瞬时流量：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#1B9EE8">
                  ${this.siteItemVo.traffic}(m³/h)
                </div>
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin:0 0 4px 4px">
                  流&emsp;&emsp;速：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#1B9EE8">
                  ${this.siteItemVo.velocity}(m/s)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <!-- 市电信息 -->
        <div class="mete">
          <div class="mete_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">市电信息</p>
          </div>
          <div class="mete_text">
            <ul class="flex ul_two">
              <li class="flex">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                  B相电流：
                </div>
                <div class="font-w-b font-s-12" style="color:#1B9EE8">
                ${this.siteItemVo.bCurrent}(A)
                </div>
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin-left:4px">
                  B相电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#1B9EE8">
                  ${this.siteItemVo.bVoltage}(V)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="solar">
          <div class="solar_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">安防报警</p>
          </div>
          <div class="solar_text">
            <ul class="flex ul_two">
              <li class="flex">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                  门禁预警：
                </div>`;
        if (this.alarmStatus.accessControlStatus == 0) {
          html += `<div class="font-w-b font-s-12" style="color:#11B36C">
                正常
                </div>`;
        } else {
          html += `<div class="font-w-b font-s-12" style="color:#FA2626" v-else>
                报警
                </div>`;
        }
        html += `
              </li>
              <li class="flex">
                <div class="t-x font-s-12" style="color:#666;margin-left:4px">
                  周界预警：
                </div>`;
        if (this.alarmStatus.perimeterStatus == 0) {
          html += `<div class="font-w-b font-s-12" style="color:#11B36C">
                正常
                </div>`;
        } else {
          html += `<div class="font-w-b font-s-12" style="color:#FA2626" v-else>
               报警
                </div>`;
        }

        html += `
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>`;

        const opts = {
          boxStyle: {
            width: "400px",
            height: "350px",
          },
          closeIconMargin: "calc(30/1080*100vh) calc(30/1920*100vw) 0 0",
          closeIconUrl: require("../../../.././assets/Newicon/lk-close.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };

        if (this.isMains == 1) {
          this.infoBox = new BMapLib.InfoBox(this.map, html1, opts);
        } else {
          this.infoBox = new BMapLib.InfoBox(this.map, html, opts);
        }
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
        var btndetail = document.querySelector('#detailClick')
        btndetail.onclick = (() => {
          this.$router.push({path: `main/StationManage/${id}`})
        })

        // 窗口关闭按钮
        var closeWindows = document.querySelector('#closeInfoWindows')
        closeWindows.onclick = (() => {
          this.lastInfoBox.close();

          this.checkboxMap = true
          this.icoQuanMap = true
        })

        // document.querySelector(".map-checkbox").style.opacity = 0; // 隐藏雨情控件
        // document.querySelector(".map-data-tip").style.opacity = 0; // 隐藏雨情控件
        // document.querySelector(".icoQuan").style.opacity = 0; // 隐藏雨情控件
        // var guanbiBtn =
        //   window.document.getElementsByClassName("rainBox_title_img");
        // var that = this;
        // guanbiBtn[0].addEventListener("click", showMsg, false); // 鼠标单击的时候调用showMes这个函数
        // function showMsg() {
        //   // alert("事件监听");
        //   that.infoBox.close();
        //   document.querySelector(".map-checkbox").style.opacity = 1; // 显示雨情控件
        //   document.querySelector(".map-data-tip").style.opacity = 1; // 显示雨情控件
        //   document.querySelector(".icoQuan").style.opacity = 1; // 显示雨情控件
        // }
      });
    },

    closeInfoBox() {
      if (this.infoBox !== null) {
        this.infoBox.close();
        document.querySelector(".map-checkbox").style.opacity = 1; // 显示雨情控件
        document.querySelector(".map-data-tip").style.opacity = 1; // 显示雨情控件
        document.querySelector(".icoQuan").style.opacity = 1; // 显示雨情控件
      }
    },
    mouseoutMap() {
      this.lastInfoBox.close();
    },
    mouseoverMap() {
      this.lastInfoBox.close();
    },
    // 渠道流量计弹框
    openDitchFlowMeterBox(lng, lat, id) {
      getDitchFlowMeter({meterId: id}).then((res) => {
        // 渠道流量计
        const qdlljHtml = qdllj(res.data);
        const opts = {
          boxStyle: {
            width: "420px",
            height: "360px",
          },
          closeIconMargin: "calc(50/1080*100vh) calc(20/1920*100vw) 0 0",
          closeIconUrl: require("../../../.././assets/images/index/guanbi.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };

        this.infoBox = new BMapLib.InfoBox(this.map, qdlljHtml, opts);
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
        document.querySelector(".map-checkbox").style.opacity = 0; // 隐藏雨情控件
        document.querySelector(".map-data-tip").style.opacity = 0; // 隐藏雨情控件
        document.querySelector(".icoQuan").style.opacity = 0; // 隐藏雨情控件
        var guanbiBtn = window.document.getElementsByClassName("title_img");
        var that = this;
        guanbiBtn[0].addEventListener("click", showMsg, false); // 鼠标单击的时候调用showMes这个函数
        function showMsg() {
          // alert("事件监听");
          that.infoBox.close();
          document.querySelector(".map-checkbox").style.opacity = 1; // 显示雨情控件
          document.querySelector(".map-data-tip").style.opacity = 1; // 显示雨情控件
          document.querySelector(".icoQuan").style.opacity = 1; // 显示雨情控件
        }
      });
    },

    closeDitchFlowMeterBox() {
      if (this.infoBox !== null) {
        this.infoBox.close();
        document.querySelector(".map-checkbox").style.opacity = 1; // 显示雨情控件
        document.querySelector(".map-data-tip").style.opacity = 1; // 显示雨情控件
        document.querySelector(".icoQuan").style.opacity = 1; // 显示雨情控件
      }
    },

    // 流量计弹框
    openFlowMeterBox(lng, lat, id) {
      getFlowMeter({devSn: id}).then((res) => {
        // console.log(res)
        // 渠道流量计
        const lljHtml = llj(res.data);
        const opts = {
          boxStyle: {
            width: "420px",
            height: "360px",
          },
          closeIconMargin: "calc(50/1080*100vh) calc(20/1920*100vw) 0 0",
          closeIconUrl: require("../../../.././assets/images/index/guanbi.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };

        this.infoBox = new BMapLib.InfoBox(this.map, lljHtml, opts);
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
        document.querySelector(".map-checkbox").style.opacity = 0; // 隐藏雨情控件
        document.querySelector(".map-data-tip").style.opacity = 0; // 隐藏雨情控件
        document.querySelector(".icoQuan").style.opacity = 0; // 隐藏雨情控件
        var guanbiBtn = window.document.getElementsByClassName("title_img");
        var that = this;
        guanbiBtn[0].addEventListener("click", showMsg, false); // 鼠标单击的时候调用showMes这个函数
        function showMsg() {
          // alert("事件监听");
          that.infoBox.close();
          document.querySelector(".map-checkbox").style.opacity = 1; // 显示雨情控件
          document.querySelector(".map-data-tip").style.opacity = 1; // 显示雨情控件
          document.querySelector(".icoQuan").style.opacity = 1; // 显示雨情控件
        }
      });
    },

    closeFlowMeterBox() {
      if (this.infoBox !== null) {
        this.infoBox.close();
        document.querySelector(".map-checkbox").style.opacity = 1; // 显示雨情控件
        document.querySelector(".map-data-tip").style.opacity = 1; // 显示雨情控件
        document.querySelector(".icoQuan").style.opacity = 1; // 显示雨情控件
      }
    },

    // 泵站弹框
    openPumpMeterBox(lng, lat, id) {
      getPumpStation({id: id}).then((res) => {
        const bzHtml = bz(res.data);
        const opts = {
          boxStyle: {
            width: "420px",
            height: "310px",
          },
          closeIconMargin: "calc(50/1080*100vh) calc(20/1920*100vw) 0 0",
          closeIconUrl: require("../../../.././assets/images/index/guanbi.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };

        this.infoBox = new BMapLib.InfoBox(this.map, bzHtml, opts);
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
        document.querySelector(".map-checkbox").style.opacity = 0; // 隐藏雨情控件
        document.querySelector(".map-data-tip").style.opacity = 0; // 隐藏雨情控件
        document.querySelector(".icoQuan").style.opacity = 0; // 隐藏雨情控件
        var guanbiBtn = window.document.getElementsByClassName("title_img");
        var that = this;
        guanbiBtn[0].addEventListener("click", showMsg, false); // 鼠标单击的时候调用showMes这个函数
        function showMsg() {
          // alert("事件监听");
          that.infoBox.close();
          document.querySelector(".map-checkbox").style.opacity = 1; // 显示雨情控件
          document.querySelector(".map-data-tip").style.opacity = 1; // 显示雨情控件
          document.querySelector(".icoQuan").style.opacity = 1; // 显示雨情控件
        }
      });
    },

    closePumpBox() {
      if (this.infoBox !== null) {
        this.infoBox.close();
        document.querySelector(".map-checkbox").style.opacity = 1; // 显示雨情控件
        document.querySelector(".map-data-tip").style.opacity = 1; // 显示雨情控件
        document.querySelector(".icoQuan").style.opacity = 1; // 显示雨情控件
      }
    },

    // 雨情弹框
    openRainBox(lng, lat, id, rainName) {
      getRainById({id}).then((res) => {
        this.rainfallTime = res.data.rainfallTime;
        this.rainfall = res.data.rainfall;
        const RainHtml = `
      <div class="RainBox">
      <button>${rainName}</button>
      <div><span>降雨量：</span><span>${this.rainfall}mm</span></div>
      <div class="timer"><span>降雨时长：</span><span>${this.rainfallTime}</span></div>
      </div>
      `;
        const opts = {
          boxStyle: {
            width: "calc(242/1920*100vw)",
            height: "calc(120/1080*100vh)",
          },
          closeIconMargin: "calc(15/1080*100vh) calc(10/1920*100vw) 0 0",
          closeIconUrl: require("../../../.././assets/images/index/rain.png"),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP,
        };
        this.infoBox = new BMapLib.InfoBox(this.map, RainHtml, opts);
        const point = new BMap.Point(lng, lat);
        const marker = new BMap.Marker(point);
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close();
        }
        this.lastInfoBox = this.infoBox;
        this.infoBox.open(marker);
      });
    },
    // 自定义经纬度高亮区域 根据自定义经纬度展示其余遮罩
    handler({BMap, map}) {
      this.map = map;
      map.setMapStyleV2(this.mapStyle);
      this.getBoundary(this.map, "兰考县");
    },
    getBoundary(map, district) {
      var bdary = new BMap.Boundary();
      bdary.get(district, function (rs) {
        // 获取行政区域
        // map.clearOverlays(); //清除地图覆盖物
        var count = rs.boundaries.length; // 行政区域的点有多少个
        if (count === 0) {
          /* alert('未能获取当前输入行政区域'); */
          return;
        }
        const EN_JW = "180, 90;";
        const NW_JW = "-180,  90;";
        const WS_JW = "-180, -90;";
        const SE_JW = "180, -90;";
        // 东南西北四个角添加一个覆盖物
        const ply1 = new BMap.Polygon(
          rs.boundaries[0] + SE_JW + SE_JW + WS_JW + NW_JW + EN_JW + SE_JW,
          {
            strokeColor: "none",
            fillColor: "none",
            fillOpacity: 1,
            strokeOpacity: 1,
          }
        );
        map.addOverlay(ply1);
        var pointArray = [];
        for (var i = 0; i < count; i++) {
          var ply = new BMap.Polygon(rs.boundaries[i], {
            strokeWeight: 2, // 设置多边形边线线粗
            strokeOpacity: 1, // 设置多边形边线透明度0-1
            StrokeStyle: "dashed", // 设置多边形边线样式为实线或虚线，取值 solid 或 dashed
            strokeColor: "#f7e3b2", //  #033353设置多边形边线颜色
            fillColor: "none", // 设置多边形填充颜色
            fillOpacity: 0.01, // 设置多边形填充颜色透明度0-1  注：标红的地放你们可以去掉看一下效果，自己体验一下
          }); // 建立多边形覆盖物
          map.addOverlay(ply); // 添加覆盖物
          // pointArray = pointArray.concat(ply.getPath())
        }
        // map.setViewport(pointArray) // 调整视野
      });
    },

    infoWindowClose(marker) {
      marker.showFlag = false;
    },
    infoWindowOpen(marker) {
      marker.showFlag = true;
    },
    // 点击地图的回调
    getClickInfo(e) {
    },
  },
};
</script>

<style lang="less" scoped>
@import "./MapSheet.less";

::v-deep.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 20px;
  padding: 8px 15px 8px 15px;
}

::v-deep .el-radio-button__inner {
  border-radius: 20px;
  padding: 8px 15px 8px 35px;
  border: none;
}

::v-deep.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 20px;
}

::v-deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 20px;
}

// 状态
.map-data-tip {
  border: 1px solid #0196ff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  width: 75px;
  max-height: 300px;
  height: 130px;
  padding: 10px;
  position: absolute;
  bottom: 13%;
  right: 21%;
  background: rgba(255, 255, 255, 0.7);
  transform: translateX(-50%);
  //display: flex;
  color: #434343;
  z-index: 5;
  opacity: 1;
  //  flex-direction: column;
  // justify-content: space-around;
}

.map-data-tipFalse {
  border: 1px solid #0196ff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  width: 75px;
  max-height: 300px;
  height: 130px;
  padding: 10px;
  position: absolute;
  bottom: 13%;
  right: 0%;
  background: rgba(255, 255, 255, 0.7);
  transform: translateX(-50%);
  //display: flex;
  color: #434343;
  z-index: 5;
  opacity: 1;
}

.typeOne {
  text-align: center;
  margin-top: 8px;
  //padding-right: 15px;

  span {
    font-size: 15px;
    font-weight: bold;
  }
}

.tip-type {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    //margin: 5px ;
    padding-right: 10px;
  }

  .gray {
    width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #676767;
  }

  .red {
    width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #f14d4d;
  }

  .green {
    width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #3d7ef2;
  }
}

.Map {
  //width: calc(1031 / 1920 * 100vw);
  width: 100%;
  height: calc(986 / 1080 * 100vh);
  position: relative;
  // 雨情状态
  .rainStatus {
    position: absolute;
    top: calc(20 / 1920 * 100vw);
    left: calc(20 / 1920 * 100vw);
    width: calc(346 / 1920 * 100vw);
    /*height: calc(300 / 1080 * 100vh);*/
    opacity: 0.8;
    border: calc(2 / 1920 * 100vw) solid #0196ff;

    .title {
      margin-top: calc(5 / 1080 * 100vh);
      position: relative;
      height: calc(50 / 1080 * 100vh);

      .line::after {
        content: "";
        width: calc(3 / 1920 * 100vw);
        height: calc(17 / 1080 * 100vh);
        background-color: #3669ab;
        position: absolute;
        left: calc(17 / 1920 * 100vw);
        top: calc(13 / 1080 * 100vh);
      }

      .title-text {
        height: 100%;
        text-indent: calc(34 / 1920 * 100vw);
        line-height: calc(44 / 1080 * 100vh);
        color: #434343;
      }
    }

    .indexLlj {
      padding: 5px 12px;
      margin-bottom: 12px;

      .indexLlj_list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .indexLlj_item {
          display: flex;
          width: 50%;
          align-items: center;
          color: #333333;
          font-weight: 500;

          .img {
            display: block;
            width: 20px;
            height: 24px;
            margin-right: 4px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .a-display {
            display: block;
            margin-left: 5px;
            color: #333;
            font-weight: bold;
            font-size: calc(20 / 1920 * 100vw);
          }

          &:nth-child(3) {
            margin-top: 10px;
          }

          &:nth-child(4) {
            margin-top: 10px;
          }
        }
      }
    }
  }

  ul {
    li {
      position: relative;
      color: #434343;

      .text {
        width: calc(48 / 1920 * 100vw);
        font-size: calc(14 / 1920 * 100vw);
        position: relative;
        left: calc(2 / 1920 * 100vw);

        p {
          position: absolute;

          left: calc(10 / 1920 * 100vw);
          bottom: 0;
        }
      }

      .numberText {
        width: calc(34 / 1920 * 100vw);
        position: relative;
        bottom: calc(1 / 1080 * 100vh);
        // p {
        //   color: #333;
        //   font-weight: bold;
        //   font-size: calc(30 / 1920 * 100vw);
        //   position: relative;
        //   font-size: calc(20 / 1920 * 100vw);
        //   // bottom: 0;
        //   top: 5px;
        // }
      }
    }
  }

  .abul {
    position: absolute;
    top: 35px;
    right: 13%;
  }

  .ul-active {
    padding-bottom: 10px;
  }

  .map {
    width: 100%;
    height: 100%;


    ::v-deep .BMap_noprint {

    }

  }
}

::v-deep .RainBox {
  width: calc(250 / 1920 * 100vw);
  height: calc(116 / 1080 * 100vh);
  background: rgba(243, 248, 255, 0.5);
  border: calc(3 / 1920 * 100vw) solid #1f78ec;
  border-radius: 8px;

  button {
    margin: calc(10 / 1080 * 100vh) 0;
    margin-left: calc(12 / 1920 * 100vw);
    width: calc(224 / 1920 * 100vw);
    height: calc(30 / 1080 * 100vh);
    background: #1f78ec;
    border-radius: 8px;
    color: #dbebf9;
    font-size: calc(18 / 1920 * 100vw);
    font-weight: bold;
    line-height: calc(20 / 1080 * 100vh) !important;
  }

  .timer {
    margin-top: calc(3 / 1080 * 100vh);
  }

  span:nth-child(1) {
    margin-left: calc(12 / 1080 * 100vh);
    font-size: calc(16 / 1920 * 100vw);
    color: #666666;
  }

  span:nth-child(2) {
    font-size: calc(16 / 1920 * 100vw);
    font-weight: bold;
    color: #333333;
  }
}

::v-deep .rain {
  padding-top: 2px;
  padding-left: 1px;
  padding-right: 1px;
  width: 400px;
  height: 390px;
  top: -40px;
  background: #217acc;
  box-shadow: 0px 0px 13px 0px rgba(6, 0, 1, 0.27);
  border-radius: 16px;

  .rainBox {
    width: 400px;
    height: 389px;
    background: #f3f6f8;
    border-radius: 16px;

    .rainBox_title {
      height: 50px;
      border-bottom: 1px dashed #ddd;
      font-weight: bold;

      .rainBox_title_num {
        width: 150px;
        color: #333;
        font-weight: 400;
      }

      .rainBox_title_text {
        width: 200px;
        color: #217acc;
      }

      .rainBox_title_img {
        // margin-left: 155px;
        width: 15px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .closeWindows {
      width: 15px;
      height: 15px;
      background-color: red;
      background: url("../../../../assets/png/closed.png") center no-repeat;
      background-size: 100%;
      cursor: pointer;
    }


    // 闸门信息
    .gate {
      height: 65px;
      border-bottom: 1px dashed #ddd;

      .gate_title {
        margin-top: 8px;

        p {
          color: #333;
          margin-left: 4px;
        }
      }

      .gate_text {
        .ul_one {
          li {
            width: 50%;
          }
        }

        .ul_two {
          li {
            width: 50%;
          }
        }
      }
    }

    // 水文信息
    .waterMes {
      height: 90px;
      border-bottom: 1px dashed #ddd;

      .waterMes_title {
        margin-top: 8px;

        p {
          color: #333;
          margin-left: 4px;
        }
      }

      .waterMes_text {
        margin-top: 5px;
      }

      .ul_one {
        li {
          width: 50%;
        }
      }

      .ul_two {
        li {
          width: 25%;
        }
      }
    }

    // 气象信息
    .mete {
      height: 55px;
      border-bottom: 1px dashed #ddd;

      .mete_title {
        margin-top: 8px;

        p {
          color: #333;
          margin-left: 4px;
        }
      }

      .mete_text {
        margin-top: 6px;

        .ul_two {
          li {
            width: 50%;
            // margin-left: 18px;
          }
        }
      }
    }

    .solar {
      height: 60px;
      border-bottom: 1px dashed #DDDDDD;

      .solar_title {
        margin-top: 8px;

        p {
          color: #333;
          margin-left: 2px;
        }
      }

      .solar_text {
        margin-top: 8px;

        .ul_two {
          li {
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
