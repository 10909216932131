<template>
  <div class="Police Card-b-c" v-loading="loading" element-loading-text="数据量过大，加载中...">
    <div class="titlePolice">
      <el-select v-model="water" placeholder="请选择" size="mini" @change="waterChange"
                 style="margin-left:10px;width: 160px;">
        <el-option
          v-for="(item,index) in stationBzList"
          :key="item.value"
          :label="index+1+' '+item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <Titles titles="站点实时信息"/>

    <div class="sitedetailsBox">
      <div class="MainBox">
        <ul>
          <li class="oneBox">
            <div class="oneBoxConone">
              <div class="title">闸前水位</div>
              <div class="valueBox">{{ siteItemVo.gateLevel }} cm</div>
            </div>
            <div class="oneBoxConThree">
              <span class="title">B相电压</span>
              <span class="value">{{ siteItemVo.bVoltage }} V</span>
            </div>
          </li>
          <li class="twoBox">
            <div class="twoBoxConone">
              <div class="title">闸后水位</div>
              <div class="valueBox">{{ siteItemVo.currentLevel }} cm</div>
            </div>
            <div class="twoBoxConThree">
              <div>控制方式</div>
              <div class="value">{{ siteItemVo.longRange == "0" ? '本地' : '在线' }}</div>
            </div>
          </li>
          <li class="threBox">
            <div class="thrBoxConone">
              <div class="title">瞬时流速</div>
              <div class="valueBox">{{ siteItemVo.velocity }} (m/s)</div>
            </div>
            <div class="thrBoxConThree">警戒水位<span class="value">{{ siteItemVo.warningLevel }} cm</span></div>
          </li>
          <li class="fourBox">
            <div class="fourBoxConone">
              <div class="title">瞬时流量</div>
              <span class="valueBox">{{ siteItemVo.traffic }} (m³/h)</span>
            </div>
            <div class="fourBoxConThree">闸孔数量<span class="value">{{ siteItemVo.gateNum }}孔</span></div>
          </li>
        </ul>
        <div style="width: 100%; margin-top: 10px; border-bottom: 1px dashed #D9D9D9"></div>
        <div class="floter">
          <div class="oneuser">
            <div class="imgbox"><img style="width: 20px;height: 20px"
                                     src="../../../../../assets/indexHomeImg/admin@2x.png" alt=""></div>
            <p class="person">责任人员：</p><span>{{ currentData.head }}</span>
          </div>
          <div class="oneuser">
            <div class="imgbox"><img style="width: 20px;height: 20px"
                                     src="../../../../../assets/indexHomeImg/time@2x.png" alt=""></div>
            <p class="person">建设时间：</p><span>{{ datetimer }}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Titles from '../../../../../components/Titles/index'
import {RecordList, getFlowMeteList, siteGetSiteAll} from '../../../../../api/index'
import {alarmLogImmediate} from '../../../../../api/index'

export default {
  created() {
    this.getData()
    this.showDown()

  },
  mounted() {

  },
  data() {
    return {
      isActive: false,
      rules: {
        description: [
          {
            required: true,
            message: '请输入处理详情',
            trigger: 'change'
          }
        ]
      },
      disposeLists: [], // 已处理
      noDisposeLists: [], // 未处理
      disposeShow: false,
      noDisposeShow: false,
      policeTotal: 0, //报警条数
      totals: 0, // 总条数
      addShow: false,
      title: '报警处理',
      typeList: [
        '水位报警',
        '电量报警',
        '设备报警',
        '门禁报警',
        '非法入侵报警'
      ],
      loading: false,
      fiveShow: false,
      alarmInfo: {},
      status: false, // 弹框类型
      waterList: [],//拦河闸下拉
      water: '',
      stationBzList: '',
      siteItemVo: '',
      alarmStatus: '', // 报警状态
      currentData: '',
      currentDatadataTime: '',
      datetimer: '', // 建设时间
    }
  },
  methods: {
    // 下拉框的数据获取
    showDown() {
      this.$get(`/site/page`, {
        page: 1,
        size: 9999
      })
        .then(res => {
          if (res.code == 200) {
            this.stationBzList = res.data.list.map(item => {
              return {
                value: item.id,
                label: item.siteName
              }
            })
            this.water = this.stationBzList[0].value
            //  根据id查询数据
            this.getCurrentUser(this.stationBzList[0].value)
            this.showDownid(this.stationBzList[0].value)

          }
        })
    },
    // 用户切换
    waterChange(val) {
      this.showDownid(val)
      this.getCurrentUser(val)
    },
    // 重新获取数据
    showDownid(val) {
      this.water = val
      let params = {
        id: val
      }
      this.$get(`/site/getSiteById`, params).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.siteItemVo = res.data.siteItemVo
            this.alarmStatus = res.data.alarmStatus
          }
        }
      })
    },

    // 获取当前用户信息
    getCurrentUser(siteIdData) {
      this.$axios.get(`/site/findById`, {
        params: {
          id: siteIdData,
        },
      })
        .then((res) => {
          if (res.data) {
            this.currentData = res.data;
            this.currentDatadataTime = res.data.dataTime;
            if (this.siteId == res.data.id) {
              this.optionValue = res.data.talkbackNumber;
              this.urlSrc = `https://lk.xuruidea.com/webapp/dev.html?a=${this.talkbackNumber}&b=${this.optionValue}`;
            }
            var timer = res.data.createTime;
            this.datetimer = timer.substring(0, 10);
          }
        });
    },
    clickPropsProce(e) {
      let index = e.target.id
      let item = JSON.parse(e.target.dataset.obj)
      this.handleProce(item)
    },
    clickPropsDetail(e) {
      let index = e.target.id
      let item = JSON.parse(e.target.dataset.obj)
      this.handleDetail(item)
    },
    // 处理数据
    getData() {
      this.loading = true
      getFlowMeteList().then(res => {
        this.noDisposeLists = res.data.ditchFlowMeters.concat(res.data.flowMeters)
        this.totals = this.noDisposeLists.length
        this.loading = false
      })
    },

    // 立即处理
    handleProce(item) {
      this.addShow = true
      this.title = '报警处理'
      this.status = true
      this.alarmInfo = item
      this.alarmInfo.type = this.typeList[item.type - 1]
      this.alarmInfo.userName = localStorage.getItem('userName')
    },
    handleDetail(item) {
      this.addShow = true
      this.title = '测流点实时信息'
      this.status = false
      this.isActive = true
      this.alarmInfo = item
      this.alarmInfo.type = this.typeList[item.type - 1]
      this.alarmInfo.userName = localStorage.getItem('userName')
    },
    // 立即处理
    handleSave() {
      // 用户信息
      let {
        userId,
        userName
      } = JSON.parse(localStorage.getItem('userInfo'))
      // 报警信息
      let {
        description,
        id
      } = this.alarmInfo
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          alarmLogImmediate({
            handlerId: userId,
            handlerName: userName,
            description,
            id
          })
            .then(res => {
              this.$message({
                message: res.message,
                type: 'success'
              })
              this.addShow = false
            })
            .catch(err => {
              this.$message({
                message: err.message,
                type: 'error'
              })
            })
            .finally(() => {
              this.getData()
            })
        }
      })
    },
    handleShow() {
      this.addShow = false
      this.alarmInfo = {}
    },
    closeBox() {
      this.$refs['ruleForm'].resetFields()
      this.isActive = false
    }
  },
  computed: {
    optionHover() {
      return {
        hoverStop: true,
        step: 0.4,
        limitMoveNum: 3
      }
    }
  },
  components: {
    Titles
  }
}
</script>

<style lang="scss" scoped>
.YearFlow {
  height: calc(265 / 1080 * 100vh);
  position: relative;
  @media screen and (max-height: 650px) {
    height: calc(268 / 1080 * 100vh);
  }
  @media screen and (max-height: 620px) {
    height: calc(270 / 1080 * 100vh);
  }

  .Flow {
    width: 100%;
    height: calc(210 / 1080 * 100vh);
  }

  .TitlesR {
    position: absolute;
    top: 8px;
    right: 5px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.Police {
  position: relative;
  width: calc(410 / 1920 * 100vw);
  height: calc(295 / 1080 * 100vh);
  margin-top: calc(17 / 1920 * 100vw);

  .Police-icon {
    width: calc(25 / 1920 * 100vw);
    height: calc(23 / 1920 * 100vw);
    border-radius: 50%;
    background: #6BC162;
    line-height: calc(23 / 1920 * 100vw);
  }

  .Police-text {
    width: calc(234 / 1920 * 100vw);
    color: #333333;
    font-weight: bold;
    margin-left: calc(5 / 1920 * 100vw);
    font-size: calc(18 / 1920 * 100vw);
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }

  .Police-btn {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #0196ff;
    border-radius: 6px;
    color: #fff;
  }

  .Police-btn-yellow {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #fd7a23;
    border-radius: 6px;
    color: #fff;
  }

  .titlePolice {
    color: red;
    position: absolute;
    top: 7px;
    right: 6px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // 站点详情
  .sitedetailsBox {
    width: 100%;
    height: 200px;
    background-color: #FFFFFF;
    display: flex;

    .MainBox {
      width: 92%;
      height: 215px;
      border-radius: 0px 0px 10px 10px;
      margin: 0 auto;
      margin-top: 10px;

      .oneBox {
        width: 100%;
        height: 35px;
        line-height: 35px;
        display: flex;
        justify-content: center;
        align-content: center;
        background-color: #DDEAFE;


        .oneBoxConone {
          width: 50%;
          color: #333333;
          font-weight: 400;
          font-family: Microsoft YaHei;
          padding-left: 6%;
          display: flex;

          .title {
            width: 60px;
            text-align: center;
          }

          .valueBox {
            color: #2485E7;
            font-weight: 600;
            padding-left: 5px;
            width: 110px;
          }
        }

        .oneBoxConThree {
          width: 50%;
          color: #333333;
          font-weight: 400;
          font-family: Microsoft YaHei;
          padding-left: 30px;

          .value {
            color: #2485E7;
            font-weight: 600;
            padding-left: 10px;
          }
        }
      }

      .twoBox {
        width: 100%;
        height: 35px;
        line-height: 35px;
        display: flex;
        justify-content: center;
        align-content: center;
        background-color: #EAF3FE;
        margin-top: 3px;

        .twoBoxConone {
          width: 50%;
          color: #333333;
          font-weight: 400;
          padding-left: 6%;
          font-family: Microsoft YaHei;
          display: flex;

          .title {
            width: 60px;
            text-align: center;
          }

          .valueBox {
            color: #2485E7;
            font-weight: 600;
            padding-left: 5px;
            width: 110px;
          }
        }

        .twoBoxConThree {
          width: 50%;
          color: #333333;
          font-weight: 400;
          font-family: Microsoft YaHei;
          padding-left: 30px;
          display: flex;

          .value {
            color: #2485E7;
            font-weight: 600;
            padding-left: 10px;
          }
        }
      }

      .threBox {
        width: 100%;
        height: 35px;
        line-height: 35px;
        display: flex;
        justify-content: center;
        align-content: center;
        background-color: #DDEAFE;
        margin-top: 3px;

        .thrBoxConone {
          width: 50%;
          color: #333333;
          font-weight: 400;
          padding-left: 6%;
          font-family: Microsoft YaHei;
          display: flex;

          .title {
            width: 60px;
            text-align: center;
          }

          .valueBox {
            color: #2485E7;
            font-weight: 600;
            padding-left: 5px;
            width: 110px;
          }
        }

        .thrBoxConThree {
          width: 50%;
          color: #333333;
          font-weight: 400;
          font-family: Microsoft YaHei;
          padding-left: 30px;

          .value {
            color: #2485E7;
            font-weight: 600;
            padding-left: 10px;
          }
        }
      }

      .fourBox {
        width: 100%;
        height: 35px;
        line-height: 35px;
        display: flex;
        justify-content: center;
        align-content: center;
        background-color: #EAF3FE;
        margin-top: 3px;

        .fourBoxConone {
          width: 50%;
          color: #333333;
          font-weight: 400;
          padding-left: 6%;
          font-family: Microsoft YaHei;
          display: flex;

          .title {
            width: 60px;
            text-align: center;
          }

          .valueBox {
            color: #2485E7;
            font-weight: 600;
            padding-left: 5px;
            width: 110px;
          }
        }

        .fourBoxConThree {
          width: 50%;
          color: #333333;
          font-weight: 400;
          font-family: Microsoft YaHei;
          padding-left: 30px;

          .value {
            color: #2485E7;
            font-weight: 600;
            padding-left: 10px;
          }
        }
      }

      .floter {
        width: 100%;
        display: flex;
        justify-content: space-between;
        line-height: 45px;

        .oneuser {
          display: flex;

          .imgbox {
            width: 20px;
            height: 20px;
            margin-top: 5px;
          }

          .person {
            padding-left: 10px;
            color: #2485E7;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.seamless-warp {
  height: calc(210 / 1080 * 100vh);
  overflow: hidden;
}

.dispose {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;

  .seamless {
    overflow: hidden;
  }
}

.contentAdd {
  padding-top: 20px;
}

.el-input,
.el-textarea,
.el-select {
  width: calc(244 / 1920 * 100vw);
}

::v-deep .el-textarea {
  height: calc(117 / 1080 * 100vh) !important;

  .el-textarea__inner {
    height: 100%;
  }
}

//::v-deep .el-input__inner {
//  border: none;
//  border-bottom: calc(1 / 1920 * 100vw) #585858 solid;
//  border-radius: 0;
//}

::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}

::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;

  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;

    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }

  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }

  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }

  .label_r {
    margin-left: 20px !important;
    text-align: left;
    color: #666;
    width: calc(86 / 1920 * 100vw);
    // margin-bottom: 10px;
  }
}

::v-deep .xiangQing {
  .el-input__inner {
    background: #fff;
  }

  .el-textarea__inner {
    background: #fff;
    margin-top: calc(6 / 1080 * 100vh);
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid transparent;
  border-color: rgba(102, 102, 102, 0.1);
}

.NoData {
  font-size: calc(20 / 1920 * 100vw);
  color: #333;
  height: calc(245 / 1080 * 100vh);
  text-align: center;
  line-height: calc(245 / 1080 * 100vh);
}

.dispose_five {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;
}

.reason {
  .el-textarea {
    height: calc(58 / 1080 * 100vh) !important;
  }
}

.el-form-item {
  margin-bottom: 0;
}

.dialogTitle {
  color: #000;
}
</style>
