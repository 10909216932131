<template>
  <div class="Police Card-b-c" v-loading="loading" element-loading-text="数据量过大，加载中...">
    <div class="titlePolice">
      <el-select v-model="water" placeholder="请选择" size="mini" @change="waterChange"
                 style="margin-left:10px;width: 160px;">
        <el-option
          v-for="(item,index) in stationBzList"
          :key="item.id"
          :label="index+1+' '+item.siteName"
          :value="item.siteName">
        </el-option>
      </el-select>
    </div>
    <Titles titles="警报状态"/>

    <div class="sitedetailsBox">
      <div class="MainBox">
        <ul>
          <li class="oneBox">
            <div class="oneBoxConone">
              <div class="ontBoxPol">
                <div class="imgboxWidth">
                  <img class="imgSrc" src="../../../../../assets/indexHomeImg/hongwau.png" alt="">
                </div>
                <p class="textpoli">红外报警</p>
              </div>
              <div class="waternormal" v-if="Alarminformation.infraRedStatus == 0">正常</div>
              <div class="waterpolice" v-else>报警</div>
            </div>
          </li>
          <li class="twoBox">
            <div class="twoBoxConone">
              <div class="ontBoxPol">
                <div class="imgboxWidth">
                  <img class="imgSrc" src="../../../../../assets/indexHomeImg/memjin.png" alt="">
                </div>
                <p class="textpoli">门禁报警</p>
              </div>
              <div class="waternormal" v-if="Alarminformation.accessControlStatus == 0">正常</div>
              <div class="waterpolice" v-else>报警</div>
            </div>
          </li>
          <li class="threBox">
            <div class="thrBoxConone">
              <div class="ontBoxPolcunchu">
                <div class="imgboxWidth">
                  <img class="imgSrc" src="../../../../../assets/indexHomeImg/cunchu.png" alt="">
                </div>
                <p class="textpoli">存储设备</p>
              </div>
              <div class="waternormal" v-if="Alarminformation.storageDeviceStatus == 0">正常</div>
              <div class="waterpolice" v-else>报警</div>
            </div>
          </li>
          <li class="fourBox">
            <div class="fourBoxConone">
              <div class="ontBoxPolzhoujie">
                <div class="imgboxWidth">
                  <img class="imgSrc" src="../../../../../assets/indexHomeImg/zhoujie.png" alt="">
                </div>
                <p class="textpoli">周界报警</p>
              </div>
              <div class="waternormal" v-if="Alarminformation.perimeterStatus == 0">正常</div>
              <div class="waterpolice" v-else>报警</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Titles from '../../../../../components/Titles/index'
import {RecordList, getFlowMeteList, siteGetSiteAll} from '../../../../../api/index'
import {alarmLogImmediate} from '../../../../../api/index'

export default {
  created() {
    this.showDown()
  },
  mounted() {

  },
  data() {
    return {
      loading: false,
      water: '',
      stationBzList: '',
      siteItemVo: '',
      Alarminformation: '',
    }
  },
  methods: {
    // 下拉框的数据获取
    showDown() {
      this.$get(`/site/page`, {
        page: 1,
        size: 9999
      })
        .then(res => {
          if (res.code == 200) {
            this.stationBzList = res.data.list
            this.water = this.stationBzList[0].siteName
            this.waterChange(this.stationBzList[0].siteName)
          }
        })
    },
    // 切换站点查看报警信息
    waterChange(val) {
      this.water = val
      this.loading = true
      this.$get('/site/page', {
        page: 1,
        size: 9999,
        siteName: val
      }).then(res => {
        if (res.code == 200) {
          this.loading = false
          this.Alarminformation = res.data.list[0].alarmStatus

          // accessControlStatus: 1 // 门禁报警
          // infraRedStatus: 0 // 红外报警
          // perimeterStatus: 0 // 周界报警
          // storageDeviceStatus: 0 // 存储报警
        }
      })
    },
    // 处理数据
    getData() {
      this.loading = true
      getFlowMeteList().then(res => {
        this.noDisposeLists = res.data.ditchFlowMeters.concat(res.data.flowMeters)
        this.totals = this.noDisposeLists.length
        this.loading = false
      })
    },
  },
  computed: {
    optionHover() {
      return {
        hoverStop: true,
        step: 0.4,
        limitMoveNum: 3
      }
    }
  },
  components: {
    Titles
  }
}
</script>

<style lang="scss" scoped>
.waternormal {
  color: #009944;
  font-weight: bold;
}

.waterpolice {
  color: red;
  font-weight: bold;
}

.YearFlow {
  height: calc(265 / 1080 * 100vh);
  position: relative;
  @media screen and (max-height: 650px) {
    height: calc(268 / 1080 * 100vh);
  }
  @media screen and (max-height: 620px) {
    height: calc(270 / 1080 * 100vh);
  }

  .Flow {
    width: 100%;
    height: calc(210 / 1080 * 100vh);
  }

  .TitlesR {
    position: absolute;
    top: 8px;
    right: 5px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.Police {
  position: relative;
  width: calc(410 / 1920 * 100vw);
  height: calc(295 / 1080 * 100vh);
  margin-top: calc(17 / 1920 * 100vw);

  .Police-icon {
    width: calc(25 / 1920 * 100vw);
    height: calc(23 / 1920 * 100vw);
    border-radius: 50%;
    background: #6BC162;
    line-height: calc(23 / 1920 * 100vw);
  }

  .Police-text {
    width: calc(234 / 1920 * 100vw);
    color: #333333;
    font-weight: bold;
    margin-left: calc(5 / 1920 * 100vw);
    font-size: calc(18 / 1920 * 100vw);
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }

  .Police-btn {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #0196ff;
    border-radius: 6px;
    color: #fff;
  }

  .Police-btn-yellow {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #fd7a23;
    border-radius: 6px;
    color: #fff;
  }

  .titlePolice {
    color: red;
    position: absolute;
    top: 7px;
    right: 6px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // 站点详情
  .sitedetailsBox {
    width: 100%;
    height: 200px;
    background-color: #FFFFFF;
    display: flex;

    .MainBox {
      width: 92%;
      height: 215px;
      border-radius: 0px 0px 10px 10px;
      margin: 0 auto;

      .oneBox {
        width: 100%;
        height: 40px;
        line-height: 40px;
        display: flex;
        border-bottom: 1px dashed #D9D9D9;
        margin-top: 2% !important;


        .oneBoxConone {
          width: 100%;
          color: #333333;
          font-weight: 400;
          font-family: Microsoft YaHei;
          display: flex;
          justify-content: space-between;

          .ontBoxPol {
            display: flex;
            justify-content: center;
            align-items: center;

            .imgboxWidth {
              width: 20px;
              height: 30px;

              .imgSrc {
                width: 20px;
                height: 20px;
              }
            }

            .textpoli {
              padding-left: 10px;
            }
          }
        }
      }

      .twoBox {
        width: 100%;
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: center;
        align-content: center;
        //background-color: #EAF3FE;
        margin-top: 2%;
        border-bottom: 1px dashed #D9D9D9;

        .twoBoxConone {
          width: 100%;
          color: #333333;
          font-weight: 400;
          display: flex;
          justify-content: space-between;
          font-family: Microsoft YaHei;

          .ontBoxPol {
            display: flex;
            justify-content: center;
            align-items: center;

            .imgboxWidth {
              width: 20px;
              height: 30px;

              .imgSrc {
                width: 20px;
                height: 20px;
              }
            }

            .textpoli {
              padding-left: 10px;
            }
          }
        }
      }

      .threBox {
        width: 100%;
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: center;
        align-content: center;
        //background-color: #DDEAFE;
        margin-top: 2%;
        border-bottom: 1px dashed #D9D9D9;

        .thrBoxConone {
          width: 100%;
          color: #333333;
          font-weight: 400;
          display: flex;
          justify-content: space-between;
          font-family: Microsoft YaHei;

          .ontBoxPolcunchu {
            display: flex;
            justify-content: center;
            align-items: center;

            .imgboxWidth {
              width: 20px;
              height: 30px;

              .imgSrc {
                width: 20px;
                height: 20px;
              }
            }

            .textpoli {
              padding-left: 10px;
            }
          }
        }
      }

      .fourBox {
        width: 100%;
        height: 40px;
        line-height: 40px;
        display: flex;
        justify-content: center;
        align-content: center;
        margin-top: 2%;
        border-bottom: 1px dashed #D9D9D9;

        .fourBoxConone {
          width: 100%;
          color: #333333;
          display: flex;
          justify-content: space-between;
          font-weight: 400;
          font-family: Microsoft YaHei;

          .ontBoxPolzhoujie {
            display: flex;
            justify-content: center;
            align-items: center;

            .imgboxWidth {
              width: 20px;
              height: 30px;

              .imgSrc {
                width: 20px;
                height: 20px;
              }
            }

            .textpoli {
              padding-left: 10px;
            }
          }
        }
      }

      .floter {
        width: 100%;
        display: flex;
        justify-content: space-between;
        line-height: 45px;

        .oneuser {
          display: flex;

          .imgbox {
            width: 20px;
            height: 20px;
            margin-top: 5px;
          }

          .person {
            padding-left: 10px;
            color: #2485E7;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.seamless-warp {
  height: calc(210 / 1080 * 100vh);
  overflow: hidden;
}

.dispose {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;

  .seamless {
    overflow: hidden;
  }
}

.contentAdd {
  padding-top: 20px;
}

.el-input,
.el-textarea,
.el-select {
  width: calc(244 / 1920 * 100vw);
}

::v-deep .el-textarea {
  height: calc(117 / 1080 * 100vh) !important;

  .el-textarea__inner {
    height: 100%;
  }
}

//::v-deep .el-input__inner {
//  border: none;
//  border-bottom: calc(1 / 1920 * 100vw) #585858 solid;
//  border-radius: 0;
//}

::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}

::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;

  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;

    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }

  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }

  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }

  .label_r {
    margin-left: 20px !important;
    text-align: left;
    color: #666;
    width: calc(86 / 1920 * 100vw);
    // margin-bottom: 10px;
  }
}

::v-deep .xiangQing {
  .el-input__inner {
    background: #fff;
  }

  .el-textarea__inner {
    background: #fff;
    margin-top: calc(6 / 1080 * 100vh);
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid transparent;
  border-color: rgba(102, 102, 102, 0.1);
}

.NoData {
  font-size: calc(20 / 1920 * 100vw);
  color: #333;
  height: calc(245 / 1080 * 100vh);
  text-align: center;
  line-height: calc(245 / 1080 * 100vh);
}

.dispose_five {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;
}

.reason {
  .el-textarea {
    height: calc(58 / 1080 * 100vh) !important;
  }
}

.el-form-item {
  margin-bottom: 0;
}

.dialogTitle {
  color: #000;
}
</style>
