<template>
  <div id="myChart" :style="{ width: '100%', height: '100%' }"></div>
</template>

<script>
let timer;

export default {
  props: {
    gateUpRecordVos: {
      type: Array,
      required: true
    }
  },
  name: "hello",
  data() {
    return {
      msg: "Welcome to Your Vue.js App"
    };
  },
  mounted() {
    setTimeout(() => {
      this.drawLine();
    }, 1500);
  },
  destroyed() {
    clearInterval(timer);
    window.removeEventListener("resize", () => {});
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));

      window.addEventListener("resize", () => {
        myChart.resize();
      });
      // 绘制图表
       const data = this.gateUpRecordVos;
      // console.log(this.gateUpRecordVos);
      myChart.setOption({
        legend: {
          orient: "vertical",
          right: 0,
          top: 10,
          formatter: function (name) {
            return name;
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
          position: ["0%", "0%"]
        },
        color: ["#77C76E", "#EB7F67", "#C9CAEA",'#EAC9EA','#EBCD67','#6EC7B9',' #6E85C7'],
        toolbox: {
          show: true
        },
        series: [
          {
            name: "闸泵开启统计",
            label: { 　　　　　　　　　　//去除饼图的指示折线label
              normal: {
              show: false,
              position: 'inside',
              formatter:"{b}:{d}%",
              },
            },
            type: "pie",
            radius: [30, 60],
            center: ["20%", "50%"],
            data:this.gateUpRecordVos
          }
        ]
      });
      // let index = 0;
      // const tt = () => {
      //   timer = setInterval(() => {
      //     if (index === data.length) index = 0;
      //     myChart.dispatchAction({
      //       type: "highlight",
      //       seriesIndex: 0,
      //       dataIndex: index
      //     });
      //     myChart.dispatchAction({
      //       type: "showTip",
      //       seriesIndex: 0,
      //       dataIndex: index
      //     });
      //     setTimeout(() => {
      //       myChart.dispatchAction({
      //         type: "downplay",
      //         seriesIndex: 0,
      //         dataIndex: index - 1
      //       });
      //     }, 1900);
      //     index += 1;
      //   }, 2000);
      // };
      // tt();
      // myChart.on("mousemove", () => {
      //   clearInterval(timer);
      // });
      // myChart.on("mouseout", () => {
      //   clearInterval(timer);
      //   tt();
      // });
    }
  }
};
</script>
