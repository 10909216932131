<template>
  <div id="YearFlowmonth"></div>
</template>

<script>
export default {
  props: {
    Traffic1: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
	    YearFlowmonth: null
    };
  },
  watch: {
    Traffic1: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      }
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.YearFlowmonth) {
      return;
    }
    this.YearFlowmonth.dispose();
    this.YearFlowmonth = null;
  },
  destroyed() {
    window.removeEventListener("resize", () => {});
  },
  methods: {
    initChart() {
      // 基于准备好的dom，初始化echarts实例
      this.YearFlowmonth = this.$echarts.init(
        document.getElementById("YearFlowmonth")
      );
      window.addEventListener("resize", () => {
        this.YearFlowmonth.resize();
      });
      // 绘制图表
      this.setOptions(this.Traffic1);
    },
    setOptions({ dataX, dataY, dataZ } = {}) {
      this.YearFlowmonth.setOption({
        tooltip: {
          trigger: 'axis',
          formatter: '{a1} {b1} 日：{c1} m³ <br> {a0} {b0} 日：{c0} m³'
        },
        xAxis: {
          data: dataX,
          type: "category",
          // boundaryGap: false, //x轴从零开始
          // x轴上的字体颜色样式
          axisLabel: {
            show: true,
            textStyle: {
              color: "#C7C7C7"
            }
          },
          // x轴上颜色样式
          axisLine: {
            lineStyle: {
              color: "#C7C7C7",
              width: 1 //这里是坐标轴的宽度
            }
          },
          axisTick: {
            //x轴刻度线
            show: false
          }
        },
        yAxis: {
          name: "单位/m³",
          type: "value",
          // y轴上的字体颜色样式
          axisLabel: {
            show: true,
            textStyle: {
              color: "#C7C7C7"
            }
          },
          // y轴上颜色样式
          axisLine: {
            lineStyle: {
              color: "#C7C7C7",
              width: 1 //这里是坐标轴的宽度
            }
          },
          axisTick: {
            //y轴刻度线
            show: false
          }
        },
        grid: {
          top: "28%",
          left: "15%",
          bottom: "18%",
          right: "5%"
        },
        series: [
          {
            data: dataY,
            type: "line",
            name: "上月"
          },
          {
            data: dataZ,
            type: "line",
            name: "本月"
          }
        ],
        color: ["#c23531", "#4073B6"],

        legend: {
          show: true,
          data: ["上月", "本月"]
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#YearFlowmonth {
  width: 100%;
  height: 100%;
}
</style>
