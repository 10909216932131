var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(_vm.zhaStatus)?_c('div',{staticClass:"zhamen-box",class:{
      'gate-bg2': _vm.gateList.length === 2,
      'gate-bg3': _vm.gateList.length === 3,
      'gate-bg4': _vm.gateList.length === 4,
      'gate-bg5': _vm.gateList.length === 5,
      'gate-bg6': _vm.gateList.length === 6,
    }},[_c('ul',{staticClass:"menUl",class:{
      'menUl2': _vm.gateList.length === 2,
      'menUl3': _vm.gateList.length === 3,
      'menUl4': _vm.gateList.length === 4,
      'menUl5': _vm.gateList.length === 5,
      'menUl6': _vm.gateList.length === 6,
    }},_vm._l((_vm.gateList.slice(0, 6)),function(item){return _c('li',{key:item.id,class:{
      'door-bg2': _vm.gateList.length === 2,
      'door-bg3': _vm.gateList.length === 3,
      'door-bg4': _vm.gateList.length === 4,
      'door-bg5': _vm.gateList.length === 5,
      'door-bg6': _vm.gateList.length === 6,
    },style:({ top: ("calc((" + (0 - Number(item.gateHeight) / Number(item.gateHeightLimit) * 20) + ") / 1920 * 100vw)") })})}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }