<template>
  <div
    class="Police Card-b-c"
    v-loading="loading"
    element-loading-text="数据量过大，加载中..."
  >
    <div class="titlePolice">
      <el-select
        v-model="water"
        placeholder="请选择"
        size="mini"
        @change="waterChange"
        style="margin-left: 10px; width: 160px"
      >
        <el-option
          v-for="(item, index) in stationBzList"
          :key="item.value"
          :label="index + 1 + ' ' + item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <Titles titles="站点监控" />

    <div class="videoBox" v-if="videoBoxFlags">
      <!-- <iframe
        style="width: 100%; height: 100%"
        allow="camera *; microphone *"
        :allowfullscreen="true"
        :src="`https://camera.xuruidea.com/index.html?list=${encodeURIComponent(
          JSON.stringify(this.videoslist[0])
        )}&num=${1}&width=${(400 / 1920) * windowWidth}&height=${
          (220 / 1080) * ((windowHeight / 100) * 90)
        }`"
        frameborder="0"
      ></iframe> -->
      <iframe
        id="myIframe"
        style="width: 100%; height: 100%"
        allow="camera *; microphone *"
        :allowfullscreen="true"
        :src="`https://camera.xuruidea.com/index.html?list=${encodeURIComponent(
          JSON.stringify(this.videoslist[0])
        )}&num=${1}&width=${(w / windowWidth) * 410}&height=${
          (h / windowHeight) * 240
        }`"
        frameborder="0"
      ></iframe>
    </div>

    <div id="voice-dev" v-show="voiceType"></div>

    <div v-if="hahah">
      <div style="width: 100%; text-align: center; line-height: 200px">
        暂无监控
      </div>
    </div>
  </div>
</template>
<script>
import Titles from "../../../../../components/Titles/index";
import {
  RecordList,
  getFlowMeteList,
  siteGetSiteAll,
} from "../../../../../api/index";
import { alarmLogImmediate } from "../../../../../api/index";
import BtnVideo1 from "./BtnVideo";
import axios from "../../../../../common/axiosHttp";

export default {
  components: {
    Titles,
    BtnVideo1,
  },
  created() {},
  mounted() {
    this.showDown();
    this.h = document.body.offsetHeight;
    this.w = document.body.offsetWidth;
  },
  data() {
    return {
      isActive: false,
      loading: false,
      water: "",
      stationBzList: "",
      video: "",
      playerInstance: null,
      videoslist: {},
      hahah: false, // 控制暂无监控显示
      falgVideo: false,
      videoBoxFlags: true,
      voiceType: false,
      windowWidth: 1920, //获取屏幕宽度,
      windowHeight: 1080, //获取屏幕高度,
      h: "",
      w: "",
    };
  },
  watch: {
    // 监听设备宽高
    windowWidth(newW, oldW) {
      let that = this;
    },
    windowHeight(newH, oldH) {
      let that = this;
    },
  },
  methods: {
    // 下拉框的数据获取
    showDown() {
      this.$get(`/site/page`, {
        page: 1,
        size: 9999,
      }).then((res) => {
        if (res.code == 200) {
          this.stationBzList = res.data.list.map((item) => {
            return {
              value: item.id,
              label: item.siteName,
            };
          });
          this.water = this.stationBzList[0].value;
          this.IndexSiteVideo(this.stationBzList[0].value);
        }
      });
    },
    // 用户切换
    waterChange(val) {
      this.water = val;
      this.IndexSiteVideo(val);
    },
    // 获取闸门视频信息
    IndexSiteVideo(siteId) {
      this.$get(`/site/getIndexSiteVideo`, {
        siteId: siteId,
      }).then((res) => {
        if (res.code == 200) {
          if (res.data && res.data.length > 0) {
            this.hahah = false;
            // 清空语音 DOM 内容，因为闸门不存在时，不执行获取语音设备的操作
            const voice = document.querySelector("#voice-dev");
            if (voice) voice.innerHTML = "";
            this.voiceType = false;
            // this.getGateVoice(res.data); // 不获取音频设备
            this.videoslist = res.data;
            this.videoBoxFlags = true;
            if (this.videoslist[0].videoType === 0) {
              this.falgVideo = true;
            }
            setTimeout(() => {
              const voice = document.querySelector("#voice-dev");
              if (voice) voice.innerHTML = "";
              this.voiceType = false;
              this.getGateVoice(res.data); // 不获取音频设备

              this.video = "";
              const videoId = document.querySelector("#video");
              if (videoId) video.innerHTML = "";
              videoId.innerHTML = "";
              this.playerInstance = new this.$ezuikit.EZUIKitPlayer({
                autoplay: true,
                id: "video",
                header: ["capturePicture", "save"],
                footer: ["hd", "fullScreen"],
                accessToken: this.videoslist[0].accessToken,
                url: this.videoslist[0].videoAddress,
                template: "standard",
                audio: 0,
              });
            }, 500);
          } else {
            this.falgVideo = false;
            this.videoBoxFlags = false;
            if (this.videoslist[0].videoType != 0) {
              this.falgVideo = false;
            }
            this.hahah = true;
            this.videoslist = undefined;
            const videoId = document.querySelector("#video");
            this.video = "";
            videoId.innerHTML = "";
          }
        }
      });
    },
    // 获取闸门语音设备信息
    async getGateVoice(VideoData) {
      new this.$ezuikit.EZUIKitPlayer({
        autoplay: true,
        id: "voice-dev",
        accessToken: VideoData[0].accessToken,
        url: VideoData[0].videoAddress,
        template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
        plugin: ["talk"], // 加载插件，talk-对讲
        // 视频下方底部控件
        footer: ["talk", "broadcast", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
        audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
      });
      // try {
      //   let gateId = null;
      //   // 清空语音 DOM 内容
      //   const voice = document.querySelector("#voice-dev");
      //   if (voice) voice.innerHTML = "";
      //   this.voiceType = false;
      //   // gateId 不存在时返回
      //   if (!gateId) return;
      //   const req = await axios.get("/video/getVideoCall", {
      //     params: {gateId},
      //   });
      //   if (req.data) {
      //     this.voiceType = true;
      //     if (voice) {
      //       new this.$ezuikit.EZUIKitPlayer({
      //         autoplay: true,
      //         id: "voice-dev",
      //         accessToken: req.data.accessToken,
      //         url: req.data.videoAddress,
      //         template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
      //         // 视频上方头部控件
      //         //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
      //         plugin: ["talk"], // 加载插件，talk-对讲
      //         // 视频下方底部控件
      //         footer: ["talk", "broadcast", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
      //         audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
      //       });
      //     }
      //   }
      // } catch (err) {
      //   this.$message.error(err.message);
      // }
    },
  },
  computed: {
    optionHover() {
      return {
        hoverStop: true,
        step: 0.4,
        limitMoveNum: 3,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep #video-container-wrap {
  width: 100% !important;
  height: 100% !important;
}
::v-deep #video-container {
  width: 50% !important;
  height: 30% !important;
}
.YearFlow {
  height: calc(265 / 1080 * 100vh);
  position: relative;
  @media screen and (max-height: 650px) {
    height: calc(268 / 1080 * 100vh);
  }
  @media screen and (max-height: 620px) {
    height: calc(270 / 1080 * 100vh);
  }

  .Flow {
    width: 100%;
    height: calc(210 / 1080 * 100vh);
  }

  .TitlesR {
    position: absolute;
    top: 8px;
    right: 5px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Police {
  position: relative;
  width: calc(410 / 1920 * 100vw);
  height: calc(295 / 1080 * 100vh);
  margin-top: calc(17 / 1920 * 100vw);

  .Police-icon {
    width: calc(25 / 1920 * 100vw);
    height: calc(23 / 1920 * 100vw);
    border-radius: 50%;
    background: #6bc162;
    line-height: calc(23 / 1920 * 100vw);
  }

  .Police-text {
    width: calc(234 / 1920 * 100vw);
    color: #333333;
    font-weight: bold;
    margin-left: calc(5 / 1920 * 100vw);
    font-size: calc(18 / 1920 * 100vw);
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }

  .Police-btn {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #0196ff;
    border-radius: 6px;
    color: #fff;
  }

  .Police-btn-yellow {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #fd7a23;
    border-radius: 6px;
    color: #fff;
  }

  .titlePolice {
    color: red;
    position: absolute;
    top: 7px;
    right: 6px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .videoBox {
    width: 100%;
    height: 215px;
    position: relative;

    .icoVideo {
      position: absolute; // 绝对定位
      bottom: 35px;
      left: 70%;
      z-index: 9999;

      ::v-deep .btn-control {
        left: -42px;
        top: -177px;
      }
    }
  }
}

.seamless-warp {
  height: calc(210 / 1080 * 100vh);
  overflow: hidden;
}

.dispose {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;

  .seamless {
    overflow: hidden;
  }
}

.contentAdd {
  padding-top: 20px;
}

.el-input,
.el-textarea,
.el-select {
  width: calc(244 / 1920 * 100vw);
}

::v-deep .el-textarea {
  height: calc(117 / 1080 * 100vh) !important;

  .el-textarea__inner {
    height: 100%;
  }
}

//::v-deep .el-input__inner {
//  border: none;
//  border-bottom: calc(1 / 1920 * 100vw) #585858 solid;
//  border-radius: 0;
//}

::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}

::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;

  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;

    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }

  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }

  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }

  .label_r {
    margin-left: 20px !important;
    text-align: left;
    color: #666;
    width: calc(86 / 1920 * 100vw);
    // margin-bottom: 10px;
  }
}

::v-deep .xiangQing {
  .el-input__inner {
    background: #fff;
  }

  .el-textarea__inner {
    background: #fff;
    margin-top: calc(6 / 1080 * 100vh);
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid transparent;
  border-color: rgba(102, 102, 102, 0.1);
}

.NoData {
  font-size: calc(20 / 1920 * 100vw);
  color: #333;
  height: calc(245 / 1080 * 100vh);
  text-align: center;
  line-height: calc(245 / 1080 * 100vh);
}

.dispose_five {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;
}

.reason {
  .el-textarea {
    height: calc(58 / 1080 * 100vh) !important;
  }
}

.el-form-item {
  margin-bottom: 0;
}

.dialogTitle {
  color: #000;
}
</style>
