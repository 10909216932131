<template>
  <div class="home">
    <div
      v-if="zhaStatus"
      class="zhamen-box"
      :class="{
        'gate-bg2': gateList.length === 2,
        'gate-bg3': gateList.length === 3,
        'gate-bg4': gateList.length === 4,
        'gate-bg5': gateList.length === 5,
        'gate-bg6': gateList.length === 6,
      }"
    >
      <ul class="menUl"
          :class="{
        'menUl2': gateList.length === 2,
        'menUl3': gateList.length === 3,
        'menUl4': gateList.length === 4,
        'menUl5': gateList.length === 5,
        'menUl6': gateList.length === 6,
      }"
      >
        <!-- <li :class="`switch${item.id}`" v-for="item of gateList.slice(0, 5)" :key="item.id" :style="{ top: `calc((${95 - Number(item.gateHeight) / Number(item.gateHeightLimit) * 20}) / 1920 * 100vw)` }"></li> -->
        <li
          :class="{
        'door-bg2': gateList.length === 2,
        'door-bg3': gateList.length === 3,
        'door-bg4': gateList.length === 4,
        'door-bg5': gateList.length === 5,
        'door-bg6': gateList.length === 6,
      }"
          v-for="item of gateList.slice(0, 6)" :key="item.id"
          :style="{ top: `calc((${0 - Number(item.gateHeight) / Number(item.gateHeightLimit) * 20}) / 1920 * 100vw)` }"></li>
      </ul>
      <!-- <div class="num" :class="`num${index + 1}`" v-for="(item, index) of gateList.slice(0, 5)" :key="item.id">{{index + 1}}</div> -->
    </div>
    <!-- <div
      v-if="gateList.length > 5 && !zhaStatus"
      class="zhamen-box"
      :class="{
        'gate-bg1': gateList.length === 6,
        'gate-bg2': gateList.length === 7,
        'gate-bg3': gateList.length === 8,
        'gate-bg4': gateList.length === 9,
        'gate-bg5': gateList.length === 10
      }"
    >
      <ul class="menUl">
        <li :class="`switch${item.id}`" v-for="item of gateList.slice(5, 10)" :key="item.id" :style="{ top: `calc((${95 - Number(item.gateHeight) / Number(item.gateHeightLimit) * 20}) / 1920 * 100vw)` }"></li>
      </ul>
      <div class="num" :class="`num${index + 1}`" v-for="(item, index) of gateList.slice(5, 10)" :key="item.id">{{index + 6}}</div>
    </div> -->
    <!-- <div class="btn left-btn" v-if="gateList.length > 5" @click="zhaStatus = zhaStatus ? false : true"></div> -->
    <!-- <div class="btn right-btn" v-if="gateList.length > 5" @click="zhaStatus = zhaStatus ? false : true"></div> -->
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    gateNum: Number,
    gateHeightLimit: Number,
    gateHeight: Number,
    gateShow: Boolean,
    gateList: Array,
  },
  data() {
    return {
      zhaStatus: true
    }
  },
  beforeUpdate() {
    // console.log(this.gateList);
    if (this.gateList.length <= 6) {
      this.zhaStatus = true
    }
  },
  watch: {
    gateHeight(e) {
      if (this.gateNum !== 0) {
        if (e === 0) {
          document.querySelector(`.switch${this.gateNum}`).style.top = 'calc(95 / 1920 * 100vw)'
        } else {
          document.querySelector(`.switch${this.gateNum}`).style.top = `calc(${(72 - e / this.gateHeightLimit * 20)} / 1920 * 100vw)`
        }
      }
    },

    gateShow(e) {
      this.zhaStatus = e
    }
  },

  methods: {}
}
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    > .btn {
      opacity: 1;
    }
  }

  .zhamen-box {
    width: 270px;
    height: 150px;
    background-size: 100% !important;
    z-index: 99;

    .menUl {
      top:57px;
      position: absolute;
      height: 61px;
      width: 138px;
      left: 135px;
      overflow: hidden;

      li {
        width: 43px;
        height: 61px !important;
        background-size: 100% !important;
        position: absolute;
        transition: top 2s;
        top: 72px;
        z-index: 1;
      }
    }

    .menUl2 {
      li {
        width: 32px !important;
      }

      li:nth-child(1) {
        left: 27px;
      }

      li:nth-child(2) {
        left: 81px;
      }
    }

    .menUl3 {
      li {
        width: 31px !important;
      }

      li:nth-child(1) {
        left: 20px;
      }

      li:nth-child(2) {
        left: 54.5px;
      }

      li:nth-child(3) {
        left: 90px;
      }
    }

    .menUl4 {
      li {
        width: 24px !important;
      }

      li:nth-child(1) {
        left: 17px;
      }

      li:nth-child(2) {
        left: 44px;
      }

      li:nth-child(3) {
        left: 71.8px;
      }

      li:nth-child(4) {
        left: 98.5px;
      }
    }

    .menUl5 {
      li {
        width: 19px !important;
      }

      li:nth-child(1) {
        left: 16px;
      }

      li:nth-child(2) {
        left: 38px;
      }

      li:nth-child(3) {
        left: 60px;
      }

      li:nth-child(4) {
        left: 83px;
      }

      li:nth-child(5) {
        left: 104px;
      }
    }

    .menUl6 {
      li {
        width: 15px !important;
      }

      li:nth-child(1) {
        left: 17px;
      }

      li:nth-child(2) {
        left: 35px;
      }

      li:nth-child(3) {
        left: 53px;
      }

      li:nth-child(4) {
        left: 71px;
      }

      li:nth-child(5) {
        left: 89px;
      }

      li:nth-child(6) {
        left: 107px;
      }
    }

    .num {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      font-size: 12px;
      font-weight: bold;
      color: #0196ff;
      border: 4px solid #0196ff;
      border-radius: 50%;
      top: 50px;
    }

    .num1 {
      left: 104px;
    }

    .num2 {
      left: 136px;
    }

    .num3 {
      left: 167px;
    }

    .num4 {
      left: 196px;
    }

    .num5 {
      left: 226px;
    }
  }

  .zhamen-bg1 {
    background: url("../../../../../assets/images/stationManage/zhamen16.png") no-repeat;
    height: 140px;
  }

  .zhamen-bg2 {
    background: url("../../../../../assets/images/stationManage/zhamen27.png") no-repeat;
    height: 140px;
  }

  .zhamen-bg3 {
    background: url("../../../../../assets/images/stationManage/zhamen38.png") no-repeat;
    height: 140px;
  }

  .zhamen-bg4 {
    background: url("../../../../../assets/images/stationManage/zhamen49.png") no-repeat;
    height: 130px;
  }

  .zhamen-bg5 {
    background: url("../../../../../assets/images/stationManage/zhamen50.png") no-repeat;
    height: 130px;
  }

  .gate-bg2 {
    background: url("../../../../../assets/images/stationManage/gate_2.png") no-repeat ;
    height: 130px;
  }

  .gate-bg3 {
    background: url("../../../../../assets/images/stationManage/gate_3.png") no-repeat;
    height: 130px;
  }

  .gate-bg4 {
    background: url("../../../../../assets/images/stationManage/gate_4.png") no-repeat;
    height: 130px;
  }

  .gate-bg5 {
    background: url("../../../../../assets/images/stationManage/gate_5.png") no-repeat;
    height: 130px;
  }

  .gate-bg6 {
    background: url("../../../../../assets/images/stationManage/gate_6.png") no-repeat;
    height: 130px;
  }

  .door-bg2 {
    width: 43px !important;
    background: url("../../../../../assets/images/stationManage/door_2.png") no-repeat;
    height: 130px;
  }

  .door-bg3 {
    width: 38px !important;
    background: url("../../../../../assets/images/stationManage/door_3.png") no-repeat;
    height: 130px;
  }

  .door-bg4 {
    width: 33px !important;
    background: url("../../../../../assets/images/stationManage/door_4.png") no-repeat;
    height: 130px;
  }

  .door-bg5 {
    width: 28px !important;
    background: url("../../../../../assets/images/stationManage/door_5.png") no-repeat;
    height: 130px;
  }

  .door-bg6 {
    width: 23px !important;
    background: url("../../../../../assets/images/stationManage/door_6.png") no-repeat;
    height: 130px;
  }

  .btn {
    position: absolute;
    width: 26px;
    height: 26px;
    background-size: 100% !important;
    border-radius: 50%;
    //opacity: 0;
    &:active {
      transform: scale(0.95);
    }
  }

  .left-btn {
    left: 36px;
    background: url("../../../../../assets/images/stationManage/zuo.png") no-repeat;
  }

  .right-btn {
    right: 36px;
    background: url("../../../../../assets/images/stationManage/you.png") no-repeat;
  }
}
</style>
