<template>
  <div class="Police Card-b-c" v-loading="loading" element-loading-text="数据量过大，加载中...">
    <div class="titlePolice">
      <el-select v-model="water" placeholder="请选择" size="mini" @change="waterChange"
                 style="margin-left:10px;width: 160px;">
        <el-option
          v-for="(item,index) in stationBzList"
          :key="item.value"
          :label="index+1+' '+item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <Titles titles="闸门状态"/>

    <div class="sluicegateBox">
      <div class="imgBox">
        <!--        <img class="imgGate" :src="sluicegateImg" alt="">-->
        <Anmation
          :gateNum="Number(valveForm.value) ? Number(valveForm.value) : 0"
          :gateList="options"
          :gateHeight="Number(gateVo.gateHeight)"
          :gateHeightLimit="Number(gateVo.gateHeightLimit)"
          :gateShow="gateShow"
        ></Anmation>
      </div>
      <div class="selectBox">
        <el-form>
          <el-form-item label="闸门">
            <el-select
              v-model="valveForm.value"
              style="width: 145px"
              @change="cutGate"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.gateName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="lineBox">
          <el-progress :percentage="percentage > 100 ? 100 : percentage" v-if="!isNaN(parseInt(percentage))"
                       class="ColorProgress"></el-progress>
        </div>
        <div class="geiHeight">
          <div class="gateHeight">闸门高度：<span>{{ gateVo.gateHeight || siteItemVo.gateHeight }}cm</span></div>
          <div class="limithigh">闸门限高：<span>{{ gateVo.gateHeightLimit || siteItemVo.gateHeightLimit }}cm</span></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Titles from '../../../../../components/Titles/index'
import {RecordList, getFlowMeteList, siteGetSiteAll} from '../../../../../api/index'
import {alarmLogImmediate} from '../../../../../api/index'
import {getGateMessage, getSitesIdList} from "../../../../../api/main";
import Anmation from "../../Table/components/Anmation2"

export default {
  created() {

  },
  mounted() {
    this.getData()
    this.showDown()
  },
  data() {
    return {
      sluicegateImg: require('../../../../../assets/indexHomeImg/gate@2x.png'),
      noDisposeLists: [], // 未处理
      totals: 0, // 总条数
      loading: false,
      water: '',
      stationBzList: '',
      siteItemVo: '',
      alarmStatus: '', // 报警状态
      datetimer: '', // 建设时间
      valveForm: {
        value: "",
        valveHeight: "", //阀门高度
      },
      options: [], // 闸门列表
      num: 0, // 第几个闸门
      size: 10, // 当页显示条数
      page: 1, // 当前页
      gateVo: {
        gateHeight: 0,
        gateHeightLimit: 0,
      }, // 闸门信息
      gateShow: true, //控制闸门图片
      percentage: 0,

    }
  },
  methods: {
    // 1、 获取传输的站点id根据id查询闸门列表取出第一个id渲染
    getInfo(val) {
      this.water = val
      getSitesIdList({id: val}).then((res) => {
        if (res.code == 200) {
          this.options = res.data
          this.valveForm.value = this.options[0].id;
          this.num = this.options[0].num;
          // 获取闸门数据
          getGateMessage({
            id: this.valveForm.value,
            size: this.size,
            page: this.page,
            num: this.num,
            siteId: this.siteId,
          }).then((res) => {
            if (res.code == 200) {
              this.valveForm.value = res.data.gateVo.gateName
              let numProcess = Number(res.data.gateVo.gateHeight) // 闸门高度
              let gateHeightLimit = Number(res.data.gateVo.gateHeightLimit) // 闸门限高
              let data = (numProcess / gateHeightLimit) * 100
              let percentage = Math.round(data)
              this.percentage = percentage
              this.gateVo = res.data.gateVo;
            }
          })
        }
      })

    },
    // 获取站点列表
    showDown() {
      this.$get(`/site/page`, {
        page: 1,
        size: 9999
      })
        .then(res => {
          if (res.code == 200) {
            this.stationBzList = res.data.list.map(item => {
              return {
                value: item.id,
                label: item.siteName
              }
            })
            this.water = this.stationBzList[0].value
            //  根据id查询数据
            this.showDownid(this.stationBzList[0].value)
            this.Gatelist(this.stationBzList[0].value)
            this.siteId = this.stationBzList[0].value
          }
        })
    },
    // 获取闸门列表
    Gatelist(val) {
      getSitesIdList({id: val}).then((res) => {
        if (res.code == 200) {
          this.options = res.data
          this.valveForm.value = this.options[0].id;
          this.num = this.options[0].num;
          // 获取闸门数据
          getGateMessage({
            id: this.valveForm.value,
            size: this.size,
            page: this.page,
            num: this.num,
            siteId: this.siteId,
          }).then((res) => {
            if (res.code == 200) {
              let numProcess = Number(res.data.gateVo.gateHeight) // 闸门高度
              let gateHeightLimit = Number(res.data.gateVo.gateHeightLimit) // 闸门限高
              let data = (numProcess / gateHeightLimit) * 100
              let percentage = Math.round(data)
              this.percentage = percentage
              this.gateVo = res.data.gateVo;
            }
          })
        }
      })
    },
    // 节制闸站点切换
    waterChange(val) {
      this.showDownid(val)
      this.siteId = val
      this.Gatelist(val)
    },
    // 获取站点数据
    showDownid(val) {
      let params = {
        id: val
      }
      this.$get(`/site/getSiteById`, params).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.siteItemVo = res.data.siteItemVo
            this.alarmStatus = res.data.alarmStatus
          }
        }
      })
    },
    // 处理数据
    getData() {
      this.loading = true
      getFlowMeteList().then(res => {
        this.noDisposeLists = res.data.ditchFlowMeters.concat(res.data.flowMeters)
        this.totals = this.noDisposeLists.length
        this.loading = false
      })
    },
    // 切换闸门
    cutGate(val) {
      this.valveForm.value = val
      getGateMessage({
        id: this.valveForm.value,
        size: this.size,
        page: this.page,
        num: this.num,
        siteId: this.siteId,
      }).then((res) => {
        if (res.code == 200) {
          // this.gateVogateHeight = Number(res.data.gateVo.gateHeight)
          let numProcess = Number(res.data.gateVo.gateHeight) // 闸门高度
          let gateHeightLimit = Number(res.data.gateVo.gateHeightLimit) // 闸门限高
          let data = (numProcess / gateHeightLimit) * 100
          let percentage = Math.round(data)
          this.percentage = percentage
          this.gateVo = res.data.gateVo;
        }
      })

    },
  },
  computed: {
    optionHover() {
      return {
        hoverStop: true,
        step: 0.4,
        limitMoveNum: 3
      }
    }
  },
  components: {
    Titles,
    Anmation
  }
}
</script>

<style lang="scss" scoped>
.YearFlow {
  height: calc(265 / 1080 * 100vh);
  position: relative;
  @media screen and (max-height: 650px) {
    height: calc(268 / 1080 * 100vh);
  }
  @media screen and (max-height: 620px) {
    height: calc(270 / 1080 * 100vh);
  }

  .Flow {
    width: 100%;
    height: calc(210 / 1080 * 100vh);
  }

  .TitlesR {
    position: absolute;
    top: 8px;
    right: 5px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.Police {
  position: relative;
  width: calc(410 / 1920 * 100vw);
  height: calc(295 / 1080 * 100vh);
  margin-top: calc(17 / 1920 * 100vw);

  .Police-icon {
    width: calc(25 / 1920 * 100vw);
    height: calc(23 / 1920 * 100vw);
    border-radius: 50%;
    background: #6BC162;
    line-height: calc(23 / 1920 * 100vw);
  }

  .Police-text {
    width: calc(234 / 1920 * 100vw);
    color: #333333;
    font-weight: bold;
    margin-left: calc(5 / 1920 * 100vw);
    font-size: calc(18 / 1920 * 100vw);
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }

  .Police-btn {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #0196ff;
    border-radius: 6px;
    color: #fff;
  }

  .Police-btn-yellow {
    width: calc(86 / 1920 * 100vw);
    height: calc(36 / 1080 * 100vh);
    border: 1px solid #e5e5e5;
    background: #fd7a23;
    border-radius: 6px;
    color: #fff;
  }

  .titlePolice {
    color: red;
    position: absolute;
    top: 7px;
    right: 6px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // 站点详情
  .sluicegateBox {
    width: 100%;
    height: 200px;
    background-color: #FFFFFF;

    .imgBox {


      .imgGate {

      }
    }

    .selectBox {
      width: 90%;
      margin: 0 auto;

      ::v-deep.el-form {
        .el-form-item {
          .el-form-item__content {
            margin: 0 !important;
            display: flex;
            justify-content: left !important;

            .el-select {
              width: 90px !important;

              .el-input {
                width: 90px;

                .el-input__inner {
                  width: 90px;
                  height: 30px;
                }

                .el-input__suffix {
                  //right: 55px;
                }
              }
            }
          }
        }
      }

      .lineBox {
        width: 100%;

        .ColorProgress {
          ::v-deep .el-progress {
            width: 100%;
          }

          ::v-deep .el-progress__text {
            color: black;
            font-size: 12px;
          }

          ::v-deep .el-progress-bar__outer {
            height: 12px !important;
            border: 1px solid #c8c8c8;
            background-color: transparent;
          }

          ::v-deep .el-progress-bar {
            .el-progress-bar__outer {
              .el-progress-bar__inner {
                background-image: linear-gradient(to right, rgba(104, 224, 207, 1), rgba(32, 156, 255, 1)) !important;
              }
            }
          }
        }
      }

      .geiHeight {
        width: 100%;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;

        .gateHeight {
          span {
            color: #2485E7;
            font-weight: bold;
          }
        }

        .limithigh {
          padding-right: 10px;

          span {
            color: #2485E7;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.seamless-warp {
  height: calc(210 / 1080 * 100vh);
  overflow: hidden;
}

.dispose {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;

  .seamless {
    overflow: hidden;
  }
}

.contentAdd {
  padding-top: 20px;
}

.el-input,
.el-textarea,
.el-select {
  width: calc(244 / 1920 * 100vw);
}

::v-deep .el-textarea {
  height: calc(117 / 1080 * 100vh) !important;

  .el-textarea__inner {
    height: 100%;
  }
}

//::v-deep .el-input__inner {
//  border: none;
//  border-bottom: calc(1 / 1920 * 100vw) #585858 solid;
//  border-radius: 0;
//}

::v-deep .el-form-item__content {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: flex-start;
}

::v-deep .el-dialog {
  background: #4073b6;
  border-radius: 16px;
  width: calc(388 / 1920 * 100vw) !important;

  .el-dialog__header {
    background: #fff;
    margin-top: 4px;
    border-radius: 16px 16px 0 0;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;
    padding: calc(15 / 1080 * 100vh) 0;

    .el-button:nth-child(1):hover {
      background: #eaeaea;
      border: 1px #eaeaea solid;
      color: #313131;
    }
  }

  // 弹框主体
  .el-dialog__body {
    background: #fff;
    height: calc(395 / 1080 * 100vh);
    // 菜单选框
    .el-checkbox-group {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      label {
        width: 34% !important;
        height: calc(25 / 1080 * 100vh);
        margin-right: 0;
      }
    }
  }

  .el-input__inner {
    border: none;
    border-radius: 4px;
    background: #f3f4f8;
  }

  .label_r {
    margin-left: 20px !important;
    text-align: left;
    color: #666;
    width: calc(86 / 1920 * 100vw);
    // margin-bottom: 10px;
  }
}

::v-deep .xiangQing {
  .el-input__inner {
    background: #fff;
  }

  .el-textarea__inner {
    background: #fff;
    margin-top: calc(6 / 1080 * 100vh);
  }
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid transparent;
  border-color: rgba(102, 102, 102, 0.1);
}

.NoData {
  font-size: calc(20 / 1920 * 100vw);
  color: #333;
  height: calc(245 / 1080 * 100vh);
  text-align: center;
  line-height: calc(245 / 1080 * 100vh);
}

.dispose_five {
  height: calc(245 / 1080 * 100vh);
  overflow: hidden;
}

.reason {
  .el-textarea {
    height: calc(58 / 1080 * 100vh) !important;
  }
}

.el-form-item {
  margin-bottom: 0;
}

.dialogTitle {
  color: #000;
}
</style>
